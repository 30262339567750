
// Title Bar
// -----------------------------------------------------------------------------

.title-bar{
    position: relative;
    overflow: hidden;
    padding: 40px 0 20px;
    text-align: center;
    z-index: 1;
    @include media-breakpoint-up(md) {
        padding: 50px 0 55px;
    }
    @include media-breakpoint-up(lg) {
        padding: 59px 0 64px;
    }
    &::before{
        @include oval(240px, $white); 
        @include media-breakpoint-up(xl) {
            @include oval(400px, $white);
            min-height: 440px;
            top: auto;
            bottom: 0px;   
            @include border-radius(0px 0px 50% 50%); 
        }         
        min-height: 440px;
        top: auto;
        bottom: 0px;   
        @include border-radius(0px 0px 50% 50%); 
        @include media-breakpoint-down(sm) { 
            @include oval(400px, $white);
            min-height: 440px;
            top: auto;
            bottom: 0px;   
            @include border-radius(0px 0px 50% 50%); 
        } 
    }
    h1{
        font-size: 30px;
        line-height: 34px;
        font-weight: 300;
        padding-bottom: 20px;
        @include media-breakpoint-up(md) {
            font-size: 56px;
            line-height: 60px;
            padding-bottom: 20px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 60px;
            line-height: 64px;
        }
    }
    .container{
        position: relative;
    }
}