
// Callout Bar
// -----------------------------------------------------------------------------

.callout-bar {
  background-position:  top center;
  background-repeat:  no-repeat;
  background-size:  cover;
  padding: 39px 0 55px;
  position: relative;
  text-align: center;
  @include media-breakpoint-up(md) {
    padding: 55px 0;
    text-align: left;
  }
  h2{
      font-size: 30px;
      line-height: 36px;
      color: $white;
      font-weight: 400;
      padding: 0 0 28px;
      max-width: 260px;
      margin: 0 auto;
      @include media-breakpoint-up(md) {
        font-size: 38px;
        line-height: 42px;
        padding: 0 0 7px;
        max-width: 100%;
      }
      @include media-breakpoint-up(lg) {
        font-size: 43px;
        line-height: 46px;
        padding: 0 0 11px;
      }
  }
  p{
      font-size: 16px;
      line-height: 22px;
      color: $white;
      font-weight: 500;
      display: none;      
      @include media-breakpoint-up(md) {
        font-size: 14px;
        line-height: 22px;
        display: block;
      }
      @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 22px;
      }
  }
  .text{
      max-width: 500px;
      @include media-breakpoint-up(md) {
        max-width: 430px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 550px;
      }
  }
  .hbspt-form .submitted-message {
    color: $white;
    font-weight: 500;
    font-size: 16px;
  }
  form{
      max-width: 480px;
      padding: 3px 0 0;
      margin: 0;
      position: relative;
      @include media-breakpoint-down(sm) {
        margin: 0 auto;
        max-width: 320px;
      }
      .hs_email > label{
        display: none;
      }
      .no-list{
        list-style: none;
        margin: 0;
        color: $white;
        font-weight: 500;
      }
      input{
          border: 1px solid $white;
          display: block;
          width: 100%;
          height: 52px;
          font-size: 16px;
          line-height: inherit;
          padding: 0 184px 0 25px;
          background: $white;
          -webkit-appearance: none;
          @include border-radius(0px);
          @include media-breakpoint-down(sm) {
            height: 46px;
            padding: 0 144px 0 20px;
            font-size: 13px;
          }
      }
      input[type="submit"]{          
          @extend .btn;
          @include position(absolute, 3px, 0px, auto, auto);
          margin: 0px;
          font-size: 16px;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 18px;
          width: 173px;
          min-width: inherit;
          height: 52px;
          -webkit-appearance: none;
          @include ps-letter-tracking(60);
          @include hover(){
            background: $primary-light-color;
            border-color: $primary-light-color;
            color: $white;
          }
          @include media-breakpoint-down(sm) {
            width: 134px;
            height: 46px;
            font-size: 14px;
            padding: 0px;
          }
      }
  }
}

.callout-bar.search-bar {
  form {
    margin: 0px auto;
  }
}