
// Breadcrumb Bar
// -----------------------------------------------------------------------------

.breadcrumb-bar {
  background: none;
  padding: 35px 0 20px;  
  &.space{
    padding: 20px 0 16px;
  }
  .breadcrumb{
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    background: none;
    li{   
      color: $black;
      font-size: 10px;
      line-height: 14px;
      @include media-breakpoint-up(md) {
        font-size: 14px;
        line-height: 24px;
      }
      @include media-breakpoint-up(lg) {
      }
      &.active{
        display: none;
      }
      &:not(:first-child):before{
        content: ">";
        font-size: 14px;
        line-height: 14px;
        padding: 0 7px;        
        vertical-align: top;
        opacity: 0.7;  
        @include media-breakpoint-up(md) {
          font-size: 19px;
          line-height: 19px;
          padding: 0 15px 0 12px;      
        }
        @include media-breakpoint-up(lg) {
        }
      }
      a{
        display: inline-block;
        vertical-align: top;
        font-size: 10px;
        line-height: 14px;
        color: $black;
        text-decoration: underline;
        font-weight: 600; 
        @include hover(){   
          text-decoration: none;
        }
        @include media-breakpoint-up(md) {
          font-size: 14px;
          line-height: 18px;
        }
        @include media-breakpoint-up(lg) {
        }             
        i{
          margin-right: 5px;
          @include media-breakpoint-up(md) {
            margin-right: 9px;
          }
          @include media-breakpoint-up(lg) {
          }
        }
      }
    }
  } 
}
