
// Sidebar
// -----------------------------------------------------------------------------

.sidebar{    
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
        padding-left: 29px;
    }
    @include media-breakpoint-down(sm) {
        padding: 10px 15px 0;
        p { text-align: center; }
    }
    h3{
        &.widget-title{
            font-size: 18px;
            line-height: 22px;
            color: $black;
            text-transform: uppercase;
            font-weight: 700;
            @include ps-letter-tracking(100);
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
    .list-group{
        padding: 0px;
        margin: 7px 0px 43px;
        list-style-type: none;
        border-top: 1px solid rgba(0,0,0,0.15);
        @include media-breakpoint-up(md) {
            margin: 7px 0px 35px;
        }
        @include media-breakpoint-up(lg) {
            margin: 7px 0px 43px;
        }        
        li{ 
            border-bottom: 1px solid rgba(0,0,0,0.15);
            a{
                text-transform: capitalize;
                color: $black;
                font-size: 14px;
                line-height: 18px;
                font-weight: 700;
                padding: 10px 0;
                @extend .row;
                margin: 0px;
                @include hover(){
                    color: $primary-color;
                    text-decoration: none;
                } 
                @include media-breakpoint-up(md) {
                    font-size: 13px;
                    line-height: 18px;
                }
                .badge{
                    margin-left: auto !important;
                    font-weight: 400;
                    font-size: 14px;
                    padding: 2px 0 0px;
                    border-radius: 0px;
                    @include media-breakpoint-up(md) {
                        font-size: 13px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 14px;
                    }
                }
            }
            .active{
                color: $primary-color; 
            }
        }
    }
    .social {
        padding: 0px 0 30px;
        @include media-breakpoint-up(md) {
            padding: 0px 0 30px;
        }
        @include media-breakpoint-up(lg) {
            padding: 0px 0 44px;
        }
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        a{
            font-size: 24px;
            line-height: 28px;
            display: inline-block;
            vertical-align: middle;
            color: $black;
            margin: 0 10px;
            @include hover(){
                color: $primary-color;
                text-decoration: none;
            }
            @include media-breakpoint-up(md) {
                margin: 0 15px 0 0;
            }
            @include media-breakpoint-up(lg) {
                margin: 0 22px 0 0;
            }
            &.linkedin{
                font-size: 28px;
            }
            &.pinterest{
                font-size: 26px;
            }
            &.instagram{
                font-size: 28px;
            }
        }
    }
    .home-ad{
        width: 100%;
        background: url(#{$assetPath}/widget-bg.jpg) no-repeat center bottom;
        background-size: cover;
        text-align: center;
        padding: 82px 47px 77px;
        margin: 14px auto 0;
        @include media-breakpoint-up(md) {
            padding: 60px 20px 77px;
            margin: 0 0 35px;
        }
        @include media-breakpoint-up(lg) {
            padding: 82px 20px 77px;
            margin: 0 0 45px;
        }
        @include media-breakpoint-down(sm) {
            max-width: 280px;
        }
        p{
            font-size: 16px;
            line-height: 20px;
            color: $white;
            font-family: $open-sans-family;
            font-style: italic;
            margin: 0 0 16px;
            @include media-breakpoint-up(md) {
                font-size: 14px;
                line-height: 18px;
                margin: 0 0 10px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: 20px;
                margin: 0 0 16px;
            }
        }
        h2{
            font-size: 37px;
            line-height: 45px;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
            font-family: $open-sans-family;
            padding: 0 0 43px;
            @include media-breakpoint-up(md) {
                font-size: 32px;
                line-height: 36px;
                padding: 0 0 30px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 37px;
                line-height: 45px;
                padding: 0 0 43px;
            }
        }
        .btn{
            background: none;
            border-color: $white;
            color: $white;
            min-width: inherit;
            margin: 0px;
            @include hover(){
                color: $primary-color;
                text-decoration: none;
                background: $white;
            }
        }
    }
    .tags{
        @extend .row;
        padding: 0px;
        margin: 0px 0 57px;
        @include media-breakpoint-up(md) {
            margin: 0px 0 40px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0px 0 57px;
        }
        a{  
            display: inline-block;
            color: $black;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 9px;
            padding: 5px 8px;
            background: $white;
            margin: 0 5px 5px 0;
            line-height: 1.3;
            @include ps-letter-tracking(60);
            @include box-shadow(0px 2px 3px rgba(0,0,0,0.08));
            @include hover(){
                text-decoration: none;
                background: none;
            }
        }
        .active {
            background: $primary-color;
        }
    }
    .related-post{
        padding: 0px 0 3px;    
        margin-bottom: 15px;
        @include media-breakpoint-down(sm) {
            padding: 5px 0 3px;   
        } 
        .related-img {
            height: 150px;
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 15px;
        }
        .image{
            margin: 0 0 18px;
            img{
                width: 100%;
                height: 150px!important;
                object-fit: cover;
            }
        }
        h4{
            font-size: 16px;
            line-height: 18px;
            color: $black;
            font-weight: 700;
            text-transform: none;
            letter-spacing: 0px;
            padding: 0 0 4px;
            a{
                color: $black;
            }
        }
    }
    .ad{
        padding: 0px;  
        margin: 0 0 41px;  
        @include media-breakpoint-down(sm) {
            display: none; 
        }    
        img{
            width: 100%;
        }
    }
    .single-bar &{        
        @include media-breakpoint-up(md) {
        }
        @include media-breakpoint-up(lg) {
            padding-left: 40px;
        }
    }
}