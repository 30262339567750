
// Product List Bar
// -----------------------------------------------------------------------------

.productlist-bar{
    padding: 26px 0 36px;
    @include media-breakpoint-down(sm) {
        padding: 10px 0 36px;
    }
    .meta{
        font-size: 11px;
        line-height: 25px;
        background: $neutral-lighter;
        display: table;
        text-transform: uppercase;
        color: $neutral-dark;
        font-weight: 800;
        padding: 0 10px 0 35px;
        margin: 0 0 3px;
        position: relative;
        @include media-breakpoint-down(sm) {
            margin: 0 0 10px;
        }
        i {
            background: $primary-color;
            color: $white;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 25px;
            text-align: center;
            padding-top: 6px;
        }
        &.digital i {
            background: $secondary-color;
        }
        &.print-digital {
            padding-left: 60px;
            i:last-child {
                left: 25px;
                background: $secondary-color;
            }
        }
        &.in-person,
        &.in-person-online,
        &.online {
            background: #FFF6E4;
            
            :first-child {
                background: #FECB66;
            }
        }
        &.online {
            :first-child {
                background: #FEA903;
            }
        }
        &.in-person-online {
            padding-left: 60px;
            
            i:nth-child(2) {
                background: #FEA903;
                left: 25px;
            }
        }
    }
    
    p{
        font-size: 14px;
        line-height: 22px;
    } 
    h3{
        font-size: 24px;
        line-height: 30px;
        color: $black;
        font-weight: 700;
        padding: 0 0 6px;
        @include media-breakpoint-down(sm) {
            font-size: 20px;
            line-height: 25px;
        }
        
    } 

    .row{       
        margin: 0px; 
        padding: 40px 0; 
        border-bottom: 1px solid rgba(0,0,0,0.1);      
        &:nth-last-of-type(1){
            padding: 40px 0px 0px;
            margin-bottom: 40px;
            border: 0px;
        }
        div[class|=col]{
            padding: 0px;     
            &.image{                
                @include media-breakpoint-up(md) {
                    padding-right: 0px;
                }
                @include media-breakpoint-up(lg) {
                    padding-right: 22px;
                }
                img{
                    width: 100%;
                }
            }
           &.text{
                padding: 30px 0 0;
                @include media-breakpoint-up(md) {
                    padding: 0 0 0 30px;
                }
                @include media-breakpoint-up(lg) {
                    padding: 0 0 0 40px;
                }
           }    
        }
    }
    ul{
        padding: 0px !important;
        margin: 2px 0 7px !important;
        border: 0px;
        list-style-type: none;  
        @include media-breakpoint-up(md) {                 
            column-count: 2;
        }
        li{             
            font-size: 14px;
            line-height: 22px;
            position: relative;
            padding: 0 0 4px 14px;
            &::before{
                content: "";
                @include position(absolute, 7px, auto, auto, 0px);
                width: 5px;
                height: 5px;
                background: $primary-color;
                @extend .rounded-circle;
            }      
        }
    } 
    .btn{
        margin: 9px 0px 0px 0;
        min-width: 162px;
    }
    &.products .btn {
        margin-right: 10px;
    }
    .product-morelink { 
        background: $neutral-lighter;
        text-align: center;
        padding: 30px 20px 10px;
        border-top: 0;
        margin-bottom: 40px;
        a {
            @include btn-solid;
        }
    }
}