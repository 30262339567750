.search-result {
    display: flex;
    margin-top: 30px;
    .breadcrumb-bar {
        padding: 10px 0 5px;
    }
}

.search-result-img {
    margin: 20px 30px 0 0;
    width: 100%;
    object-fit: contain;
    align-self: flex-start;
}

.result-text-right {
    width: 80%; 
    padding: 10px 20px;
    p {
        font-size: 16px;
        line-height: 26px;
    }
}

.my-crumbs a { 
    font-weight: 700; 
    color: #676666;
}

.my-crumbs a:hover { 
    text-decoration: underline;
}

.my-crumbs a:not(:last-child):after { 
    content: "/"; 
    opacity: 0.3; 
    padding: 0 4px 0 4px; 
    font-size: 15px; 
    text-decoration: none; 
    line-height: 14px; 
    display: inline-block
}

@media (max-width: 768px) {
    .search-result {
        display: flex;
        flex-direction: column;
    }
    
    .search-result-img {
        width: auto;
        margin-bottom: 10px;
    }
    
    .result-text-right{
        padding: 0px;
        width: auto;
    }
}

.myimg {
    display: inline-block;
    vertical-align: top;
    margin: 20px 30px 0 0;
}

.mytxt {
    display: inline-block;
    width: 75%;
}

.my-crumbs a { 
    font-weight: 600; 
    color: #000; 
    text-decoration:underline;
}

.my-crumbs a:hover { 
    text-decoration: none;
}

.my-crumbs a:not(:last-child):after { 
    content: ">"; 
    opacity: 0.3; 
    padding: 0 15px 0 12px; 
    font-size: 19px; 
    text-decoration: none; 
    line-height: 14px; 
    display: inline-block
}

@media (max-width: 620px) {
    .myimg {
        display: block;
        vertical-align: top;
        margin: 0px;
    }
    .mytxt {
        display: block;
        width: 100%;
    }
}