
// Mobile Filter Bar
// -----------------------------------------------------------------------------
$dropshadows: inset 0 15px 15px -15px rgba(0,0,0,0.12);
.mobile-filter{
    padding: 0px;
    background: $white;
    border-bottom: 1px solid rgba(0,0,0,0.15);    
    @include media-breakpoint-up(md) {
        display: none;
    }
    .filter-toggle{
        font-size: 18px;
        line-height: 24px;
        color: $black;
        display: inline-block;
        vertical-align: top;
        position: relative;
        font-weight: 700;
        text-transform: uppercase;
        padding: 18px 20px;
        @include ps-letter-tracking(100);
        @include hover(){
            text-decoration: none;
        }
        &.active{
            color: $primary-color;
        }
        &.active:after{
            content: fa-content($fa-var-minus-circle);
        }
        &:after{
            @extend .fas;
            content: fa-content($fa-var-plus-circle);                        
            border: 0px;
            font-size: 16px;
            line-height: 24px;
            display: inline-block;
            vertical-align: top;
            margin: 0 0 0 8px;  
            color: $primary-color;          
        }
    }    
}
.filter-drop-down{
    @extend .sidebar;
    background: $neutral-lighter;
    padding: 26px 20px 35px;
    @include box-shadow($dropshadows);
    display: none;
    h3{
        &.widget-title{
            font-size: 14px;
            line-height: 18px;
            padding-bottom: 11px;
            font-weight: 900;
        }
    }
    .list-group{
        margin: 7px 0px 33px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        border-top: 0;
        li {
            flex: 0 31%;
            margin-right: 10px;
            @include media-breakpoint-down(xs) {
                flex: 0 45%;
            }
        }
    }
    .list-group li a{
        padding: 8px 0;
        font-size: 12px;
    }
    .tags{
        margin: 5px 0 0px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    .tags a{
        font-size: 10px;
        line-height: 16px;
        padding: 5px 10px;
        letter-spacing: 0px;
        margin: 0 5px 5px 0;
    }
} 

