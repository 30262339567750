
// Hover Effects
// -----------------------------------------------------------------------------
.fc-event { 
	pointer-events: none;
	background: $primary-color !important;
    border-color: $primary-color !important;
}
.fc-day-grid-event .fc-content {
	white-space: normal !important;
}
.fc-toolbar .fc-center {
	float: right;
}
.fc-unthemed .fc-today {
	background: $neutral-lighter !important;
}
.container {
	@include media-breakpoint-up(lg) {
		max-width: 900px; 
	}
	@include media-breakpoint-down(sm) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

*{
	margin: 0px;
	padding: 0px;
	scroll-margin-top: 5rem;
	@include media-breakpoint-down(md) {
		scroll-margin-top: 6rem;
	}
}

img{
	@include img-responsive();
	height: auto!important;
}
button,
input[type=submit], input, textarea{
	-webkit-appearance: none;
}
button,
input[type=submit],
a {
	transition:width 250ms ease, background-color 250ms ease, border-color 250ms ease, color 250ms ease, padding 250ms ease, margin 250ms ease;
	svg {
		* {
			transition:fill 250ms ease;
		}
	}
}

hr{
	margin: 37px 0 22px;
	@include media-breakpoint-up(md) {
		margin: 25px 0 15px;
	}
	@include media-breakpoint-up(lg) {
		margin: 37px 0 22px;
	}
}

.caption{
	&.float-left{
		margin: 20px 33px 20px 0;
		@include media-breakpoint-up(md) {
			margin: 20px 20px 20px 0;
		}
		@include media-breakpoint-up(lg) {
			margin: 20px 33px 20px 0;
		}
		@include media-breakpoint-down(sm) {
			margin: 0 -20px;
			padding: 20px 0 0;
			float: none !important;
			width: auto;
			img{
				width: 100%;
			}
		}
	}
	&.float-right{
		margin: 10px 0px 20px 34px;
		@include media-breakpoint-up(md) {
			margin: 10px 0px 20px 20px;
		}
		@include media-breakpoint-up(lg) {
			margin: 10px 0px 20px 34px;
		}
		@include media-breakpoint-down(sm) {
			margin: 0 -20px;
			padding: 20px 0 0;
			float: none !important;
			width: auto;
			img{
				width: 100%;
			}
		}
	}
	p{
		font-size: 11px !important;
		line-height: 14px !important;
		opacity: 0.7;
		margin: 18px 0 !important;
		position: relative;
		padding: 0 0 0 21px;
		@include media-breakpoint-down(sm) {
			margin: 18px 20px !important; 
		}
		&:before{
			content: "";
			@include position(absolute, 2px, auto, auto, 0px);
			width: 2px;
			height: calc(100% - 5px);
			background: $primary-color;
			display: block;
		}
	}
}

.body-wrapper{
	width: 100%;
	overflow: hidden;
	padding: 70px 0px 0px;	
	@include media-breakpoint-up(md) {
		padding: 110px 0 0;
	}
	@include media-breakpoint-up(lg) {
		padding: 130px 0 0;
	}
}

input, button, select, textarea{
	@include hover(){
		outline: none;
	}
}

.border-top{
	border-top: 2px solid $primary-color !important;
}

.back-to-top{
	@include position(fixed, auto, auto, 30px, 0px);
	@include border-radius(0px 50% 50% 0);	
	width: 38px;
	height: 40px;
	text-align: center;
	font-size: 0px;
	color: $white;
	line-height: 0px;
	display: none;
	background: $back-to-top-color url(#{$assetPath}/back-to-top.png) 40% 47.5% no-repeat;	
	@include transition(opacity 350ms);
	z-index: 100;
	@include media-breakpoint-down(sm) {
		display: block;
		opacity: 0;
		&.show{
			display: block;
			opacity: 1;
		}		
	}
}

a.menuToggle {	
	@include transition(all 350ms);
	@include position(absolute, 6px, -15px, auto, auto); 
	height:56px; 
	text-decoration: none; 
	background: none; 
	width: 60px; 
	z-index: 10; 	
	margin:0; 
	display: none; 
	font-size: 0px; 
	line-height: 0px; 
	@include media-breakpoint-down(sm) {
		display: block;
	}
	span { 
		width: 30px; 
		height: 3px; 
		display: block; 		 
		background: $black; 
		@include transition(all 350ms);		
		z-index:9999;
		@include position(absolute, 28px, 15px, auto, auto); 
	}
	span:after { 
		width: 30px; 
		height: 3px; 		
		content:''; 
		@include position(absolute, -9px, auto, auto, 0px); 
		@include transition(all 350ms);	
		background: $black; 		
	}
	span:before { 
		width: 20px;
		height: 3px; 		
		content:''; 
		background: $black; 		
		@include position(absolute, auto, auto, -9px, 0px); 
		@include transition(all 350ms);
	}
	&.active{
		span{ 
			background: transparent; 
			&:after{
				@include translate(rotate(45deg));
				top: 0;
				background: $primary-color;
			}
			&:before{
				@include translate(rotate(-45deg));
				bottom: 0;
				width: 30px;
				background: $primary-color;
			}
		}
	}
}

.searchToggle{
	@include transition(all 350ms);
	@include position(absolute, 0px, 50px, auto, auto); 
	width: 72px;
	height: 70px;
	border-left: 1px solid rgba(0,0,0,0.15);
	border-right: 1px solid rgba(0,0,0,0.15);
	font-size: 24px;
	color: $black;
	line-height: 70px;
	text-align: center;
	display: none;
	@include media-breakpoint-down(sm) {
		display: block;
	}
	&.active{
		color: $primary-color;
	}
}
.bootstrap-select .dropdown-menu{
	padding: 0px;
	margin: 0px;
}
.bootstrap-select .dropdown-menu li a span.text{
	min-height: inherit !important;
	padding: 0px;
	border: 0px;
}
.bootstrap-select .dropdown-item { 
	padding: 11px 24px 7px 15px; 
	font-size: 14px; 
	line-height: 18px; 
	@include hover(){
		background: $primary-color;
		color: #fff;
	}
}
.bootstrap-select .dropdown-item.active, 
.bootstrap-select .dropdown-item:active{
	background: $primary-color;
	color: $white;
}

.spacer {
	height: 1px;
	background-color: $neutral;
}

.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

.hs-form {
    ul {
        list-style: none;
        margin: 0 !important;
        li {
            &:before {
                content: none !important;
            }
        }
    }
    label {
        margin: 10px 0 0 0;
    }
    .hs-input {
        display: inline-block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: $core-font-color;
        border: 1px solid $form-input-border-color;
    }
    
    input[type="checkbox"] {
        -webkit-appearance: checkbox;
        margin-right: 10px;
        width: auto;
    }
    
    input[type="radio"] {
    	-webkit-appearance: radio;
	    width: auto;
	    margin-right: 10px;	
    }
    
    .hs-error-msgs {
        li {
            color: $red-color; 
            label {
                margin-top: 0;
            }
        }
    }
    
    .hs-button {
        background: $button-background-color;
        border: 2px solid $button-border-color;
        border-radius: 0;
        color: $white;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        margin: 9px 20px 3px 0;
        outline: 0;
        padding: 10px 25px;
        position: relative;
        text-align: center;
        text-decoration: none;
        min-width: 180px;
        -webkit-appearance: none;
        cursor: pointer;
    }
}

@media print 
{
		.body-wrapper {
			overflow: visible !important;
			padding: 0;
		}
		a {
			text-decoration: none !important;
		}
		.callout-bar,
		header,
		footer {
			display: none !important;
		}
		body {
			font: 12pt Georgia, "Times New Roman", Times, serif;
			margin: 0;
			padding: 0;
			overflow: visible !important;
		}
		.d-print-block img {
			width: 127px;
		}
		.page-banner {
			display: none !important;
			&:after {
				content: none !important;
				display: none !important;
			}
		}
		.info-bar {
			overflow: visible !important;
			&:before {
				content: none !important;
			}
		}
		.fc-event {
			border: none !important;
		}
		td {
			padding: .5em;
		}
		
		table {
        	border: 1px solid $neutral-light !important;
		}
    
}

#cookieConsent {
    background-color: rgba(20,20,20,0.80);
    min-height: 26px;
    font-weight: 600;
    color: $neutral-lighter;
    padding: 20px 0 15px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    > .container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: flex-start;
		}
    }
    p {
    	margin: 0 20px 0 0;
    }
    a:hover {
    	color: $primary-color;
    }
}

.anchor-before {
	margin-top: -68px !important;
	padding-top: 68px !important;
	
	&.product-row {
		padding-top: 128px !important;
	}
}