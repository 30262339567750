
// Related Post Bar
// -----------------------------------------------------------------------------

.relatedpost-bar{
    padding: 36px 0 38px;    
    margin: 0px;
    background: $neutral-lighter;
    @include media-breakpoint-up(md) {
        padding: 50px 0 50px;  
    }
    @include media-breakpoint-up(lg) {
        padding: 70px 0 95px;  
    }
    h2{
        font-size: 24px;
        line-height: 30px;
        color: $black;
        padding: 0 0 25px;
        font-weight: 600;
        text-align: center; 
        @include media-breakpoint-up(md) {
            font-size: 28px;
            line-height: 32px;
            padding: 0 0 12px;
            text-align: left;
        }
        @include media-breakpoint-up(lg) {
            font-size: 30px;
            line-height: 36px;
            padding: 0 0 19px;
        }
    }
    .meta{
        font-size: 14px;
        line-height: 18px;
        color: $primary-color;
        font-weight: 600;
        display: block;
        padding: 0;
    }
    h3{
        font-size: 18px;
        line-height: 24px;
        color: $black;
        font-weight: 600;
        padding: 0 0 10px;
        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 28px;
            padding: 0 0 7px;
        }
        @include media-breakpoint-up(lg) {
        }
    }
    p{
       margin: 0 0 16px;
    }
    .btn{
        margin: 0px;
        min-width: 162px;
        padding: 10px 15px;
        background: none;
        &.fill {
		    background-color: $button-background-color;
		    color: $white;    
	    }
    }
    .row{ 
        margin: 0; 
        background: $white;
        margin: 20px 0 0px;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
        [class|=col]{
            padding: 0px;
            margin: 0px;
            &.image{      
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                flex: 0 0 29.4%;
                max-width: 29.4%;
                @include media-breakpoint-down(sm) {
                    max-width: none;
                    width: 100%;
                    height: 175px;
                }
            }
            &.text{
                padding: 25px 25px 30px;
                flex: 0 0 70.4%;
                max-width: 70.4%;
                text-align: left;
                @include media-breakpoint-down(sm) {
                    max-width: none;
                }
                @include media-breakpoint-up(lg) {
                    padding: 25px 32px 30px 50px;
                }
            }
        }
    }
}