
// Interested Bar
// -----------------------------------------------------------------------------

.interested-bar{
    padding: 0px;
    text-align: center;
    margin: -197px 0 0;
    position: relative;
    @include media-breakpoint-down(sm) {
        margin: -100px 0 0;
    }
    h2{
        font-size: 30px;
        line-height: 36px;
        color: $black;
        font-weight: 500;
        padding: 0 0 17px;
        @include media-breakpoint-down(sm) {
            font-size: 26px;
            line-height: 30px;
            padding: 0 0 10px;
        }
    }
    p{
        font-size: 14px;
        line-height: 18px;
        color: $black;
        margin: 0 0 19px;
    }
    .inner{        
        margin: 0 auto;
        background: $white;
        padding: 30px 25px;
        @include box-shadow(0 40px 100px -40px rgba(0,0,0,0.1));
        @include media-breakpoint-up(md) {
            padding: 45px 70px;
        }
        @include media-breakpoint-up(lg) {
            padding: 51px 107px 54px;
        }
    }
    .btn{
        font-size: 16px;
        line-height: 20px;
        margin: 9px 0 0px;
        min-width: 237px;
        padding: 13px 25px;
        font-weight: 600;        
        @include media-breakpoint-down(sm) {
            min-width: 220px;
        }
    }
}