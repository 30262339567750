
// Whatnew Bar
// -----------------------------------------------------------------------------

.whatnew-bar{
    padding: 80px 0px 70px;      
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 70px 0px;  
    }
    @include media-breakpoint-up(lg) {
        padding: 94px 0px 87px;  
    }
    &::before{
        @include oval(620px, $green-dark-color);
    }
    .container{
        position: relative;
    }
    .info{
        max-width: 705px;
        margin: 0 auto;        
        padding: 0 0 38px;
        text-align: center;
        @include media-breakpoint-up(md) {
            padding: 0 0 15px;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 0 38px;
        }
        h2{
            font-size: 30px;
            line-height: 36px;
            font-weight: 500;
            color: $white;
            padding: 0  0 16px;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                line-height: 50px;
                padding: 0  0 10px;
                font-weight: 400;
            }
            @include media-breakpoint-up(lg) {
                font-size: 54px;
                line-height: 58px;
                padding: 0  0 16px;
            }
        }
        p{
            font-size: 16px;
            line-height: 28px;
            color: $white;
            @include ps-letter-tracking(10);
            @include media-breakpoint-up(md) {
                font-size: 14px;
                line-height: 22px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: 22px;
            }
        } 
    } 
    .row{
        margin: 0px -10px;  
        @include media-breakpoint-up(md) {
            margin: 0px -7px;  
        }
        @include media-breakpoint-up(lg) {
            margin: 0px -13px;  
        }     
        div[class|=col]{
            padding: 0px 10px 25px;  
            @include media-breakpoint-up(md) {
                padding: 0px 7px;
            }
            @include media-breakpoint-up(lg) {
                padding: 0px 13px;
            }
            @include media-breakpoint-down(sm) {
                max-width: 50%;
                flex: 0 0 50%;
            }
            .image{
                margin: 0 0 18px;
                img{
                    width: 100%;
                    height: 150px!important;
                    object-fit: cover;
                }
                .category{
                    @include position(absolute, auto, auto, 12px, 0px);
                    @extend .d-flex;
                    @extend .flex-wrap;
                    span{
                        font-size: 10px;
                        line-height: 14px;
                        color: $black;
                        padding: 4px 7px 1px;
                        background: $white;
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                }
            }  
            h4{
                font-size: 15px;
                line-height: 19px;
                color: $white;
                font-weight: 700;
                text-transform: none;
                padding: 0 0 9px;
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }       
            p{
                font-size: 13px;
                line-height: 1.4;
                color: $white;
                margin: 0px;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            .read-more{
                display: table;
                font-size: 14px;
                line-height: 18px;
                font-weight: 700;
                color: $white;
                margin: 8px 0 20px;
                text-decoration: underline;
                position: relative;
                @include hover(){
                    text-decoration: none;
                }
                &:after {
                    @extend .fas;
                    content: fa-content($fa-var-chevron-right);
                    position: absolute;
                    top: 3px;
                    right: -15px;
                }                
            }
        }
    }
    .btn{
        display: table;
        color: $black;
        border-color: $white;
        background: $white;
        min-width: 158px;
        margin: 0px auto;
        @include box-shadow(0px 10px 12px rgba(0,0,0,0.14));
        @include hover(){
            background: $primary-color;
            border-color: $primary-color;
            color: $white;
        }
        @include media-breakpoint-up(md) {
            margin: 30px auto 0;
        }
        @include media-breakpoint-up(lg) {
            margin: 51px auto 0;
        }
        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 20px;
            min-width: 190px;
            padding: 16px 15px;
        }
    }
}