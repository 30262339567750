
// Related Product
// -----------------------------------------------------------------------------

.relatedproduct-bar{
    padding: 75px 0 30px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 110px 0 30px; 
    }
    @include media-breakpoint-up(lg) {
        padding: 134px 0 47px;
    }
    &::before{
        @include oval(640px, $white); 
        @include media-breakpoint-up(md) {
            @include oval(512px, $white);
        }
        @include media-breakpoint-up(lg) {
            @include oval(280px, $white);
        } 
        @include media-breakpoint-up(xl) {
            @include oval(512px, $white);
        }        
    }
    .meta{
        display: block;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 600;     
        color: $primary-color;
        padding: 0 0 3px;
        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 18px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    h3{
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        padding: 0 0px 15px 0;
        @include media-breakpoint-up(md) {
            font-size: 26px;
            line-height: 30px;
            padding: 0 0px 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 32px;
            line-height: 36px;
            padding: 0 0px 25px 0;
        }
    }
    .name{
        display: block;
        font-size: 12px;
        line-height: 18px;
        color: $black;
        font-weight: 500;
        font-style: italic;
        padding: 0 0 15px;
        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 18px;
            padding: 0 0 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 22px;
            padding: 0 0 29px;
        }
    }
    p{
        font-size: 14px;
        line-height: 22px;
        padding-right: 20px;
    }
    img{
        float: left;
        margin: 0 15px 20px 0;
        max-width: 110px;        
        @include media-breakpoint-up(md) {
            margin: 0 20px 20px 0;
            width: auto;
            max-width: 135px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0 34px 20px 0;
        }
    }
    .row{       
        padding: 0 0 40px;     
        @include media-breakpoint-down(sm) {
            padding: 0px;
        }   
        div[class|=col]{
            @include media-breakpoint-up(md) {
                max-width: 48%;
                flex: 0 0 48%;
            }         
            &:nth-of-type(2){
                margin-left: auto !important;
                @include media-breakpoint-up(md) {
                    max-width: 47%;
                    flex: 0 0 47%;
                }                
            }            
        }
    }
    .btn{
        margin: 8px 0 0;
        min-width: 192px;
        @include media-breakpoint-down(sm) {
           margin: 0px 0 35px;
        }
    }
}