
// Contributor Bar
// -----------------------------------------------------------------------------

.contributor-bar{
    padding: 46px 0 20px;
    text-align: center;
    @include media-breakpoint-up(md) {
        padding: 60px 0 30px;
    }
    @include media-breakpoint-up(lg) {
        padding: 66px 0 37px;
    }
    .inner{
        padding: 5px;
        height: 100%;
    }
    h2{
        font-size: 30px;
        line-height: 34px;
        font-weight: 400;
        padding-bottom: 35px;
        @include media-breakpoint-up(md) {
            font-size: 45px;
            line-height: 50px;
            padding-bottom: 35px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 54px;
            line-height: 58px;
            padding-bottom: 47px;
        }
    }
    h3{
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: $black;
        padding: 0px 0 3px;
        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 18px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .designation{
        display: block;
        font-size: 10px;
        line-height: 14px;
        color: $black;
        padding: 0 0 2px;
        text-transform: uppercase;
    }
    .social{
        a{
            font-size: 10px;
            line-height: 14px;
            color: $black;
            display: inline-block;
            vertical-align: top;
            margin: 0 2px;
            @include hover(){                
                text-decoration: none;     
                color: $primary-color;               
            } 
            .fa-linkedin-in{
                font-size: 12px;
            }
        }
    }
    .row{
        margin: 0px -7px;
        @extend .justify-content-center;
        .col{
            padding: 0 7px 14px;
            max-width: 100%;
            flex: 0 0 100%;
            @include media-breakpoint-up(sm) {
                flex: 0 0 33.333333%;
                padding: 0 7px 15px;
            }
            @include media-breakpoint-up(md) {
                flex: 0 0 25%;
                padding: 0 7px 29px;
            }
            img{
                margin: 0 auto 16px;  
                max-width: 125px;
                height: auto;
                @extend .rounded-circle;
            }
        }
    }
    .users {
        display: flex;
        justify-content: center;
    }
    i.fal.fa-user-circle {
        font-size: 130px;
        padding-bottom: 15px;
        color: $neutral-light;
    }
}