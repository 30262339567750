
// Footer
// -----------------------------------------------------------------------------

.footer-cntr {
    padding: 23px 0 43px; 
    @include media-breakpoint-up(md) {
        padding: 35px 0 40px;
    }
    @include media-breakpoint-up(lg) {
        padding: 45px 0 43px;
    }   
    .container {
        @include media-breakpoint-up(lg) {
            max-width: 1170px; 
        }
    }
    .footer-logo{
        display: block;
        width: 102px;
        margin: 0 auto 15px;
        @include media-breakpoint-up(md) {
            margin: 0 0 20px 0px;
            width: 78px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0 0 20px 15px;
        }
        img {
            width: 102px;
            @include media-breakpoint-up(md) {
                width: 78px;
            }
        }
    }
    .social{
        padding: 9px 0 7px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        @include media-breakpoint-up(md){
            padding: 0 0 22px;
            text-align: left;
            p {
                font-size: 14px;
            }
        }
        @include media-breakpoint-up(lg) {
            padding: 0 0 22px 20px;
            p {
                padding: 0;
                font-size: 16px;
            }
        }
        p { margin: 0; }
        a {
            color: $black;
            display: inline-block;
            vertical-align: top;
            margin: 0 20px 0 0;
            @include hover(){ 
                color: $primary-color;
                text-decoration: none;
            }
            @include media-breakpoint-down(sm) {
                margin: 0 20px;
                font-size: 28px;
                line-height: 28px;
                &.twitter{
                    font-size: 26px;
                }
                &.pinterest{
                    font-size: 26px;
                }
            }
        }
    }
    p{
        padding: 0 0 20px;
        font-size: 12px;
        line-height: 16px;
        opacity: 0.7;
        text-align: center;
        @include media-breakpoint-up(md) {
            padding: 0px;
            text-align: left;
            font-size: 9px;
            line-height: 14px;
        }    
        @include media-breakpoint-up(lg) {
            padding: 0 0 0 20px;
        }
    }
    h3{
        font-size: 15px;
        line-height: 20px;
        color: $black;
        font-weight: 400;
        padding: 0 0 18px;
        &.active:after{
            content: fa-content($fa-var-chevron-up);
        }
        &:after{
            @extend .fas;
            content: fa-content($fa-var-chevron-down);
            border: 0px;
            font-size: 14px;
            line-height: 24px;
            display: none; 
            margin: 0 0 0 10px;
        }
        @include media-breakpoint-down(sm) {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            padding: 0px 0 0 20px;   
            &:after{
                display: inline-block;
                vertical-align: top;
            }         
        }        
    }
    .text{	
        @include media-breakpoint-up(md) {	
            display: block !important;	
        }	
        @include media-breakpoint-down(sm) {	
            display: none;	
        }        	
    }
    ul{
        padding: 0px;
        margin: 0px;
        list-style-type: none; 
        @include media-breakpoint-down(sm) {
            padding: 10px 0 0;
        }  
        li{
            padding-bottom: 4px;
            font-size: 11px;
            line-height: 16px;
            @include media-breakpoint-down(sm) {
                font-size: 13px;
                line-height: 18px;
                padding-bottom: 7px;
                &:last-child{
                    padding-bottom: 0px;
                }
            }
            a{
                color: $black;
                opacity: 0.7;  
                @include hover(){
                    opacity: 1;
                    text-decoration: none;
                }             
            }
        }
    }
    .col-md-9{  
        @extend .d-flex;
        @extend .justify-content-end;  
        @include media-breakpoint-down(sm) {
            display: block !important;
            text-align: center;
        }            
        .links{
            padding: 0;
            @include media-breakpoint-up(md){
                padding: 3px 20px 0 0;
            }
            @include media-breakpoint-up(lg){
                padding: 3px 34px 0 0;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                padding: 0 0 20px;
            }
            &:nth-of-type(1){
                padding-right: 0px;
                @include media-breakpoint-up(md){
                    padding-right: 20px;
                }
                @include media-breakpoint-up(lg){
                    padding-right: 47px;
                }
            }
            &:nth-last-of-type(1){
                @include media-breakpoint-up(md){
                    padding-right: 0px;
                }
                @include media-breakpoint-up(lg){
                    padding-right: 34px;
                }
            }
        }
    }
}

#intercom-container .intercom-app .intercom-launcher-frame {
    @include media-breakpoint-up(md) {
        bottom: auto;
        top: 20px;
    }
}