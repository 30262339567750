
// Single Bar
// -----------------------------------------------------------------------------

.single-bar{
    padding: 0px 0 26px; 
    @include media-breakpoint-up(md) {
        padding: 4px 0 30px; 
    }
    @include media-breakpoint-up(lg) {
        padding: 4px 0 63px; 
    }  
    .category{
        font-size: 9px;
        line-height: 12px;
        color: $white;
        font-weight: 700;
        display: table;
        background: $secondary-color;
        text-transform: uppercase;
        @include ps-letter-tracking(120);
        padding: 4px 5px 2px 9px;
        margin: 0 0 10px;
        @include media-breakpoint-down(sm) {
            margin: 0 auto 10px;
        }
        &.yellow{
            background: $yellow-color;
        }
        &.green{
            background: $green-color;
        }
        &.red{
            background: $red-color;
        }
        &.blue{
            background: $secondary-color;
        }
        &.general-tag{
            background: $primary-color;
        }
    }
    h3:not(.widget-title){
        font-size: 20px;
        line-height: 26px;
        color: $black;
        padding: 5px 0 15px;
        font-weight: 700;
        @include ps-letter-tracking(40);        
    }
    .meta{
        font-size: 10px;
        line-height: 14px;
        color: $neutral-darker;
        font-weight: 500;
        display: block;
        padding: 0 0 14px;
        @include ps-letter-tracking(20); 
    }
    p, ol, ul {
        font-size: 18px;
        line-height: 28px;  
    }
    p {
        color: $black;
        @include ps-letter-tracking(20);
        margin: 0 0 15px;    
    }
    h1{
        font-size: 32px;
        line-height: 33px;
        color: $black;
        font-weight: 500;
        padding: 0 0 8px;
        text-align: center;
        @include media-breakpoint-up(md) {
            font-size: 36px;
            line-height: 40px;
            text-align: left;
        }
        @include media-breakpoint-up(lg) {
            font-size: 42px;
            line-height: 46px;
        }
    }
    .share{
        font-size: 16px;
        line-height: 22px;
        color: $black;
        padding: 0 0 19px;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        span{
            display: inline-block;
            vertical-align: top;
            padding-right: 12px;
            @include media-breakpoint-down(sm) {
                padding: 0 6px;
            }
        }
        i{
            font-size: 18px;
            margin: 2px 8px 0 2px;
            vertical-align: top;
            display: inline-block;
        }
    }
    .note{
        border-top: 1px solid rgba(0,0,0,0.6);
        padding: 25px 0 0;
        margin: 30px 0 0;
        @include media-breakpoint-up(md) {
            padding: 16px 0 0;
            margin: 35px 0 0;
        }
        @include media-breakpoint-up(lg) {
            padding: 16px 0 0;
            margin: 53px 0 0;
        }
        p{
            font-size: 10px;
            line-height: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            margin-bottom: 20px;                      
            strong{
                display: block;
            }
        }
    }
    .left-part{
        flex: 0 0 67.3%;
        max-width: 67.3%;
        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 33px 15px 0;
        }
        .ad{
            margin: 0 0 40px;
            @include media-breakpoint-up(md) {
                margin: 0 0 30px;
            }
            @include media-breakpoint-up(lg) {
                margin: 0 0 40px;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
            img{
                width: 100%;
            }
        }
        .tags{
            padding: 0 0 4px;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
            a{
                font-size: 10px;
                line-height: 12px;
                font-weight: 600;
                color: $black;
                padding: 3px 12px 1px;
                text-transform: uppercase;
                border: 1px solid rgba(0,0,0,0.2);
                display: inline-block;
                vertical-align: top;
                margin: 0 1px 1px 0;                
            }
        }
    }
    .sidebar{
        flex: 0 0 32.7%;
        max-width: 32.7%;
        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%; 
        }
        @include media-breakpoint-down(sm) {
            .category{
                margin: 0 0 10px;
            }
            .meta{
                text-align: left;
            }
        }
    }        
}