
// Mobile Navigation
// -----------------------------------------------------------------------------
$dropshadows: 0 15px 15px -15px rgba(0,0,0,0.15), inset 0 15px 15px -15px rgba(0,0,0,0.15);
#mobile-navigation {
  width: 100%;
  height: calc(100vh - 70px);
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @include position(absolute, 70px, auto, auto, 0px);
  @include media-breakpoint-up(md) {
    display: none !important;
  }
  .drop-down{           
    .drop-down-menu-style{
      @include position(absolute, 0px, auto, auto, 100%);
      @include transition(left 350ms);
      background: $neutral-lighter;
      @include box-shadow($dropshadows);
      width: 100%;  
      overflow: hidden;
      &.active{
        left: 0px;
      }    
      &.level-1{
        left: 0px;
      }  
      &.prev{
        left: -100%;
      }
      ul{
        padding: 52px 0px 0;
        margin: 0px;
        list-style-type: none;
        li{
          font-size: 18px;
          line-height: 24px;
          color: $black;
          font-weight: 600;
          border-top: 1px solid rgba(0,0,0,0.15);
          position: relative;
          .menu-toggle{
            @include position(absolute, 0px, 0px, auto, auto);
            background: $primary-color;
            width: 56px;
            height: 56px;
            text-align: center;
            line-height: 56px;
            display: block;
            color: $white;
            font-size: 18px;
          }
          a{
            padding: 16px 35px;
            color: $black;
            display: block;
            text-decoration: none;
            @include hover(){
              text-decoration: none;
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  .back-btn{
    @include position(absolute, 0px, 0px, auto, auto);
    font-size: 16px;
    line-height: 20px;
    color: $primary-color;
    font-weight: 600;
    padding: 18px 15px 0 0;
    display: block;
    i{
      font-size: 18px;
      margin: 1px 3px 0 3px;
      display: inline-block;
      vertical-align: top; 
    }
  }

  .widget{
      padding: 30px 0px 45px;
      width: 100%;     
      background: $primary-light-color;
      position: relative;
      @include box-shadow(inset 0 15px 15px -15px rgba(0,0,0,0.15));      
      &:after{
          content: "";
          width: 25000px;
          height: 100%;
          background: $primary-light-color;  
          @include box-shadow($dropshadows);         
          @include position(absolute, 0px, -24970px, auto, auto);                
      }
      &:before{
          @include arrow(bottom-left, $primary-dark-color, 200px);
          @include position(absolute, auto, auto, 0px, 0px);
          border-bottom-width: 170px;
          border-right-width: 270px;          
      }
      img{
          float: left;
          margin: 3px 30px 0 0;
          position: relative;
          @include box-shadow(14px 5px 14px rgba(0,0,0,0.15)); 
          max-width: 85px;         
      }
      p{
          font-size: 16px;
          line-height: 20px;
          color: $white;
          font-style: italic;
          font-weight: 400;
          margin: 2px 0 0 0;
          font-family:  $open-sans-family;
          position: relative;
          z-index: 1;
      }
      h3{
          font-size: 27px;
          line-height: 1.4;
          font-weight: 700;
          color: $white;
          text-transform: uppercase;
          font-weight: 700;
          font-family:  $open-sans-family;
          position: relative;
          z-index: 1;          
      }
      .btn{
          border-color: $white;  
          min-width: 138px;
          background: none;
          margin: 0 0 40px 0;
          color: $white;
          position: relative;
          z-index: 1;
      }
  }
}
