@charset "UTF-8";

// Slick icon entity codes output the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-arrow-color: white !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

$slick-dot-background-color:$white;
$slick-dot-background-color-active:$primary-color;
$slick-dot-background-color-hover:$primary-color;
$slick-dot-border-color:$white;
$slick-dot-border-color-active:$primary-color;
$slick-dot-border-color-hover:$primary-color;
$slick-dot-border-width:0px;
$slick-dot-click-size:22px;
$slick-dot-visible-size:11px;

/* Slider */

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


.slick-list {
	.slick-loading & {
		//background:#fff url('assets/images/ajax-loader.gif') center center no-repeat;
	}
}

.slick-slide {
	outline:none;
}

/* Arrows */

.slick-prev,
.slick-next {
	display: block;
	height: 90px;
	width: 90px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	@include position(absolute, 50%, -44px, auto, auto);		
	padding: 0;
	margin-top: -45px;
	border: none;
	outline: none;
	background-color: #d95b37;
	background-position: 20px 50%;
	background-repeat: no-repeat;
	background-image: url(#{$assetPath}/next-btn.png);
	background-size: 13px auto;
	z-index: 99;	
	@extend .rounded-circle;
	@include hover(){ 
		background-color: $primary-light-color;
	}
	@include media-breakpoint-down(sm){ 
		width: 80px;
		height: 80px;
		margin-top: 68px;	
		right: -42px;	
		background-position: 18px 50%;
	}
	&:hover, &:focus {
		outline: none;		
		color: transparent;		
	}	
}

.slick-prev {
	right: auto;
	left: -44px;
	background-position: 55px 50%;	
	background-image: url(#{$assetPath}/prev-btn.png);
	@include media-breakpoint-down(sm) {
		left: -42px;
		background-position: 49px 50%;	
	}
}

/* Dots */

.slick-dots {
	bottom:0px;
	display:block;
	list-style:none;
	margin:0;
	padding:0 0 19px 0;
	position:absolute;
	text-align:center;
	width:100%;
	@include media-breakpoint-down(sm) {
		top: 160px;
		padding: 0px;
		bottom: auto;
	}
	li {
		cursor:pointer;
		display:inline-block;
		margin:0;
		padding:0;
		position:relative;
		button {
			align-items:center;
			background:transparent;
			border:0;
			color:transparent;
			cursor:pointer;
			display:flex;
			font-size:0px;
			height:$slick-dot-click-size;
			justify-content:center;
			line-height:0px;
			padding:0;
			outline:none;
			width:$slick-dot-click-size;
			@include media-breakpoint-down(sm) {
				width: 18px;
				height: 18px;
			}
			&:hover, 
			&:focus {
				outline:none;
				&:before {
					background:$slick-dot-background-color-hover;
					border-color:$slick-dot-border-color-hover;
				}
			}
			&:before {
				background:$slick-dot-background-color;
				border:$slick-dot-border-width solid $slick-dot-border-color;
				border-radius:50%;
				content:'';
				display:block;
				font-size:0;
				height:$slick-dot-visible-size;
				width:$slick-dot-visible-size;
				transition:background 500ms ease;
				@include media-breakpoint-down(sm) {
					width: 9px;
					height: 9px;
				}
			}
		}
		&.slick-active button:before {
			background:$slick-dot-background-color-active;
			border-color:$slick-dot-border-color-active;
		}
	}
}
