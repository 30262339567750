
// CTA Bar
// -----------------------------------------------------------------------------

.cta-bar{
    padding: 55px 0 35px;
    text-align: center;    
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 60px 0 53px;
    }
    @include media-breakpoint-up(lg) {
        padding: 77px 0 53px;
    }
    .container{
        position: relative;
    }
    .inner{
        max-width: 600px;
        margin: 0 auto;
    }
    h2{
        font-size: 26px;
        line-height: 30px;
        color: $black;
        font-weight: 500;
        padding: 0 0 15px;
        @include media-breakpoint-up(md) {
            font-size: 28px;
            line-height: 31px;
            padding: 0 0 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 30px;
            line-height: 34px;
            padding: 0 0 22px;
        }
    }
    p{
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 20px;
        @include media-breakpoint-down(sm) {
            margin: 0 0 10px;
        }
    }
}