
// Product Listing Bar
// -----------------------------------------------------------------------------

.productlisting-bar{
    background: $neutral-lighter;
    margin: -100px 0;
    padding: 110px 0 120px;
    @include media-breakpoint-up(md) {
        margin: -100px 0;
        padding: 110px 0 120px;
    }
    @include media-breakpoint-up(lg) {
        margin: -133px 0;
        padding: 133px 0 147px;
    }
    .meta{
        display: block;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 600;     
        color: $primary-color;
    }
    h3{
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        padding: 0 0 10px;
        @include media-breakpoint-up(md) {
            font-size: 32px;
            line-height: 36px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 34px;
            line-height: 38px;
        }
    }
    .name{
        display: block;
        font-size: 14px;
        line-height: 22px;
        color: $black;
        font-weight: 500;
        font-style: italic;
        padding: 0 0 10px;
        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 20px;
            padding: 0 0 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 22px;
            padding: 0 0 20px;
        }
    }
    p{
        font-size: 14px;
        line-height: 22px;
    }
    .row{
        margin: 0;
        padding: 40px 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        @include media-breakpoint-up(md) {
            margin: 0;
            padding: 40px 0;
        }
        @include media-breakpoint-up(lg) {
            margin: 0px 0 6px;
            padding: 48px 0 54px;
        }
        &:nth-last-of-type(1){
            border: 0px;
        }
        &:nth-of-type(2n){            
            div[class|=col]{
                padding: 0px;
                &.image{
                    padding: 0;  
                    @extend .order-0;  
                    @include media-breakpoint-down(sm) {
                        order: 0 !important;
                    }                               
                }
                &.text{
                    padding: 30px 0px 0px;
                    @include media-breakpoint-up(md) {
                        padding: 0px 15px 0 30px;
                    }
                    @include media-breakpoint-up(lg) {
                        padding: 0px 15px 0 75px;
                    }
                }
            }
        }
        div[class|=col]{
            padding: 0;
            &.image{
                margin-top: 1px;
                @extend .order-2;
                @include media-breakpoint-up(md) {
                    max-width: 33.6%;
                    flex: 0 0 33.6%;
                }
                @include media-breakpoint-down(sm) {
                    order: 0 !important;
                } 
                img{
                    width: 100%;
                }
            }
            &.text{
                padding: 30px 0px 0px;                
                @include media-breakpoint-up(md) {
                    padding: 0 30px 0 0;
                    max-width: 66.4%;
                    flex: 0 0 66.4%;
                }
                @include media-breakpoint-up(lg) {
                    padding: 0 84px 0 0;
                }
            }
        }
    }
    .btn{
        margin: 16px 0 0;
        min-width: 195px;
        @include media-breakpoint-down(sm) {
            margin: 5px 0 0;
        }
    }
}