
// Team Bar
// -----------------------------------------------------------------------------

.team-bar{
    text-align: center;
    padding: 151px 0 240px;
    margin: -101px 0 0;  
    position: relative;
    overflow: hidden;  
    @include media-breakpoint-down(sm) {
        padding: 151px 0 130px;
    }
    &::before{
        @include oval(600px, $neutral-lighter);   
        @include border-radius(0px 0px 50% 50%); 
        @include media-breakpoint-down(sm) {
            @include oval(1400px, $neutral-lighter); 
        }        
    }
    .inner{
        background: $white;
        padding: 0px 0px 10px 0px;
        height: 100%;
        @include box-shadow(0 20px 12px -12px rgba(0,0,0,0.02));
        // img {
        //     max-height: auto;
        //     width: 20px;
        //     height: auto;
        //     width: auto;
        //     padding: 0px 67px;
        //     margin: none;
        // }
    }
    h2{
        font-size: 30px;
        line-height: 34px;
        font-weight: 300;
        padding-bottom: 40px;
        @include media-breakpoint-up(md) {
            font-size: 56px;
            line-height: 60px;
            padding-bottom: 70px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 60px;
            line-height: 64px;
            padding-bottom: 134px;
        }
    }
    h3{
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: $black;
        padding: 0px 0 3px;
        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 18px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .designation{
        display: block;
        font-size: 10px;
        line-height: 14px;
        color: $black;
        padding: 0 0 2px;
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
            font-size: 9px;
            line-height: 12px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 10px;
            line-height: 14px;
        }
    }
    .social{
        a{
            font-size: 12px;
            line-height: 14px;
            color: $primary-color;
            display: inline-block;
            vertical-align: top;
            margin: 7px 2px;
            span{
                text-decoration: underline;
            }
            @include hover(){                
                text-decoration: none;     
                color: $black;               
            } 
            .fa-linkedin-in{
                font-size: 12px;
            }
        }
    }
    .row{
        margin: 0px -7px;
        @include media-breakpoint-up(sm) {
            margin: 0px -5px;
        }
        @include media-breakpoint-up(md) {
            margin: 0px -5px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0px -7px;
        }
        .col{
            padding: 0 7px 14px;   
            max-width: 100%;
            flex: 0 0 100%;    
            @include media-breakpoint-up(sm) {
                padding: 0 5px 10px;
                flex: 0 0 50%;
            }    
            @include media-breakpoint-up(md) {
                padding: 0 5px 10px;
                flex: 0 0 25%;
            }
            @include media-breakpoint-up(lg) {
                padding: 0 7px 14px;
            }
            img{
                margin: 0 auto;
                width: 100%;
                height: 200px!important;
                width: auto;
                padding: 10px 0;
            }
        }
    }
    .users {
        display: flex;
        justify-content: center;
    }
    i.fal.fa-user-circle {
        font-size: 180px;
        padding-bottom: 20px;
        color: $neutral-light;
    }
}