
// Pagination
// -----------------------------------------------------------------------------

.pagination-bar {
    background: none;
    padding: 20px 0px 40px;
    ul{
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      li{
        font-family:  Arial, Helvetica, sans-serif;
        color: $neutral-darker;
        font-size: 15px;
        line-height: 20px;
        margin: 0 2px;
        border-radius: 5px;
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            color: $neutral-darker;
            height: 30px;
            width: 32px;    
        }
        @include hover() {
            background: $neutral-lightest;
            a {
                text-decoration: none;       
                color: $neutral-darkest;
            }
        }
        &.navPrev, &.navNext {
            border: 1px solid $neutral-darker;
            border-radius: 23px;
            a { 
                padding: 5px 15px; 
                width: 60px;
            }
            @include hover() {
                border-color: $primary-color;
                background: none;
            }
        }
        &.active{
            background: $primary-color; 
            a { color: $white; }
        }
      }
    }
  }
