
// Productinfo Bar
// -----------------------------------------------------------------------------

.productinfo-bar{    
    padding: 34px 0 79px;
    position: relative; 
    overflow: hidden;  
    @include media-breakpoint-up(md) {
        padding: 34px 0 90px;
    }
    @include media-breakpoint-up(lg) {
        padding: 34px 0 79px;
    } 
    &::before{
        @include oval(512px, $neutral-lighter);    
        @include border-radius(0px 0px 50% 50%);   
        @include media-breakpoint-down(sm) {
            @include oval(750px, $neutral-lighter);    
            @include border-radius(0px 0px 50% 50%); 
        } 
    }
    .container{
        position: relative;
    }
    .row>.col-md-4 {
        display: flex;
    }
    .meta{
        font-size: 18px;
        line-height: 24px;
        color:  $black;
        text-transform: uppercase;
        font-weight: 400;
        display: block;
        @include ps-letter-tracking(40);
        padding: 0 0 7px;
        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 24px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 21px;
            line-height: 26px;
        }
        
    }
    
    h2{
        font-size: 26px;
        line-height: 30px;
        color: $black;
        font-weight: 600;
        padding: 0 0 5px;
        @include media-breakpoint-up(md) {
            font-size: 38px;
            line-height: 42px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 42px;
            line-height: 46px;
        }
    }
    .name{
        display: block;
        font-size: 12px;
        line-height: 16px;
        color: $black;
        font-weight: 500;
        font-style: italic;
        padding: 0 0 15px;
        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 16px;
            padding: 0 0 24px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 13px;
            line-height: 18px;
        }
    }
    p{
        font-size: 16px;
        line-height: 24px;
    }
    .inner{
        background: $white;
        margin: 17px 0 3px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .image{
            position: relative;
            margin: 0px;
            img{
                width: 100%;
            }
        }
        p{
            font-size: 14px;
            line-height: 22px;
            color: $neutral;
            padding: 23px 25px 10px;
            margin: 0px;     
        }
        .more-btn{
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            color: $primary-color;
            float: right;
            margin: 0 30px 20px 0;
            text-decoration: underline;
            align-self: flex-end;
            margin-top: auto;
            @include hover(){                
                text-decoration: none;                    
            } 
            @include media-breakpoint-up(md) {
                font-size: 13px;
                line-height: 18px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .row{       
        margin: 0 -10px;               
        div[class|=col]{
            padding: 0 10px;           
        }
    }
}