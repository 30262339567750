
// Buttons
// -----------------------------------------------------------------------------

.btn {
	background: $button-background-color;
	border: 2px solid $button-border-color;
	border-radius: 0;
	color: $button-font-color;
	display: inline-block;
	font-family: $core-font-family;
	font-size: 14px;
	font-weight: 700;	
	line-height: 18px;
	margin: 9px 20px 3px 0;
	outline: 0;
	padding: 10px 25px;
	position: relative;
	text-align: center;
	text-decoration: none;	
	min-width: 180px;
	-webkit-appearance: none;
	@include media-breakpoint-up(md) {
		min-width: 215px;
	}
	@include hover() {
		background: $button-background-color-hover;
		border-color: $button-border-color-hover;
		color: $button-font-color-hover;
		text-decoration: none;
		@include box-shadow(none);				
	}
	&.btn-primary {
		background: $button-secondary-background-color;
		border-color: $button-secondary-border-color;
		color: $button-secondary-font-color;
		@include hover() {
			background: $button-secondary-background-color-hover;
			border-color: $button-secondary-border-color-hover;
			color: $button-secondary-font-color-hover;
			@include box-shadow(none);
		}
	}
}

.subscribeButton{
	p{ 
		margin-bottom: 0;
	}
}
