
// Header
// -----------------------------------------------------------------------------
$dropshadows: 0 15px 15px -15px rgba(0,0,0,0.15);
.header-cntr {
  @include position(fixed, 0px, auto, auto, 0px);
  width: 100%;
  z-index: 9999;
  background: $white;
  @include clearfix();
  @include transition(all 350ms);
  @include box-shadow($dropshadows);
  @include media-breakpoint-down(sm) {
    height: 70px;
  }
  &.header-fixed{
    .fixed-search.open{
        input[type="search"]{
          width: 130px;
          padding: 0 0px 0 15px;
        }
      }
    .top-bar{
      margin-top: -60px;
    }
    .navbar-nav{
      padding: 38px 0 0;
      width: 70.5%;
      @include media-breakpoint-up(md) {
        width: 80%;
      }
      @include media-breakpoint-up(lg) {
        width: 80%;
        padding: 28px 0 0;
      } 
      @include media-breakpoint-up(xl) {
        width: 75%;
      }
      li{
        a:not(.btn){
          padding-bottom: 23px;
          &.dropdown-toggle{
            padding-bottom: 23px;
          }
        }        
      }      
    }
    nav{
      @include clearfix();
      .navbar-brand{
        bottom: -8px;
        @include media-breakpoint-up(md) {
          bottom: -52px;
        }
        @include media-breakpoint-up(lg) {
          bottom: -54px;
        }
        &::after{
          opacity: 1;
          visibility: visible;
        }
      }
      form{
        &.fixed-search{
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .container {
    width: 100%;
    max-width: 960px;
    @include media-breakpoint-up(xl) {
      max-width: 1170px; 
    }
  }
  nav{
    margin: 0;
    position: relative;
    @include media-breakpoint-up(md) {
      margin: 13px 0px 0 0px;
    }
    @include media-breakpoint-up(lg) {
      margin: 13px 0px 0 0px;
    }
    @include media-breakpoint-up(xl) {
      margin: 13px 20px 0 15px;
    }
    .navbar-brand{
      float: left;
      padding: 0px;
      margin: 0;
      position: relative;
      bottom: -8px;
      z-index: 1001;
      @include transition(all 350ms);
      @include media-breakpoint-up(md) {
          bottom: 0px;
      }
      @include media-breakpoint-up(lg) {
          max-width: 127px;
      }
      img{
        position: relative;
        width: 127px;
        height: auto;
        z-index: 1;
      }
      &::after{
        content: "";
        width: 76px;
        height: 42px;
        display: block;
        opacity: 1;
        visibility: visible;
        @include transition(opacity 350ms);
        background: url(#{$assetPath}/logo-shadow.png) bottom center no-repeat;
        @include position(absolute, auto, auto, -16px, 0px);
        @include media-breakpoint-up(md) {
          left: -1px;
          opacity: 0;
          visibility: hidden;
        }   
        @include media-breakpoint-up(lg) {
          left: 0px;
        }      
      }
    }
    form{
      position: relative;
      padding: 0 0 0 30px;
      margin: 0 0 7px;
      input[type="search"]{
        border: 0px;
        display: block;
        height: 24px;
        background: none;
        font-size: 11px;
        line-height: inherit;
        color: $black;
        padding: 0px;
      }
      button{
        @include position(absolute, 0px, auto, auto, 0px);
        background: none;
        border: 0px;
        height: 24px; 
        font-size: 15px;
        color: $black;
        padding: 0px;     
        cursor: pointer;  
        @include hover(){ 
            text-decoration: none; 
        } 
      }
    }
    .fixed-search{
      @include position(absolute, auto, 0px, 22px, auto);
      width: 24px;
      margin: 0px;
      opacity: 0;
      visibility: hidden;
      @include transition(opacity 350ms);      
      input[type="search"]{
        width: 25px;
        float: right;
        width: 0px;
        margin: 0 30px 0 0;
        background: $white;
        @include transition(width 350ms);
      }
      button{
        right: 0px;
        left: auto;
      }
      @include media-breakpoint-down(sm) {
        @include position(absolute, 120px, auto, auto, 0px);
        opacity: 1;
        visibility: visible;
        width: 100%; background: $white;
        @include box-shadow($dropshadows);
        display: none;
        padding: 0px;
        input[type="search"]{
          width: 100%;
          padding: 0 15px;
          height: 46px;
          margin: 0px;
          font-size: 14px;
        }
        button{
          right: 0px;
          left: auto;
          width: 46px;
          height: 46px;
          color: $white;
          background: $primary-color;
        }
      }
    }
  }
  .top-bar{
    @extend .ml-auto;
    @extend .d-flex;
    @include transition(margin 350ms);
    width: 75%;
    @include media-breakpoint-down(sm){ 
      display: none !important;
    }
    @include media-breakpoint-up(md) {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      width: 80%;
    }
    @include media-breakpoint-up(xl) {
      width: 75%;
    } 
    > .row {
      width: 100%;
      margin: 0;
      > .col-md-9, .col-md-3 {
        padding: 0;
      }
    }
    .top-links{
      padding: 0px;
      @extend .ml-auto;
      margin: 0px;
      .mura-object-content {
        display: flex;
        justify-content: flex-end;
      }
      li{
        display: inline-block;
        vertical-align: top;
        font-size: 10px;
        line-height: 16px;
        color: $black;
        padding: 0 0 0 10px;
        @include media-breakpoint-up(lg) {
          padding: 0 0 0 18px;
          font-size: 11px;
        } 
        &:first-child{
          @include media-breakpoint-down(md) {
            padding-left: 0; 
          }
        }
        a{
          color: $black;
          padding: 3px 0 0;
          display: block;
          @include hover(){ 
              color: $primary-color; 
              text-decoration: none; 
          }
          i{
            display: inline-block;
            vertical-align: top;
            margin: 0 7px 0 0;
            font-size: 16px;
            line-height: 16px;
            color: $primary-color;
            &.fa-desktop{
              font-size: 15px;
            }
          }
        } 
      }
    }
  }
}