
// Home Feature Bar
// -----------------------------------------------------------------------------

.homefeature-bar{
    padding: 65px 0px 62px; 
    margin: -100px 0 0; 
    overflow: hidden;
    text-align: center;
    position: relative;
    @include media-breakpoint-up(md) {
        padding: 150px 0px 60px; 
        margin: -140px 0 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 181px 0px 102px; 
        margin: -156px 0 0;
    }
    @include media-breakpoint-up(xl) {
    }
    &::before{
        @include oval(620px, $secondary-color);
        @include media-breakpoint-down(sm) {
            @include oval(620px, $secondary-color);
        }
    }
    .container{
        position: relative;
    }
    .info{
        max-width: 775px;
        margin: 0 auto;        
        padding: 0 20px 28px;
        @include media-breakpoint-up(md) {
            padding: 0 0 28px;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
        }
        h2{
            font-size: 30px;
            line-height: 36px;
            font-weight: 400;
            color: $white;
            padding: 0  0 15px;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                line-height: 50px;
                padding: 0  0 15px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 54px;
                line-height: 58px;
            }
            @include media-breakpoint-up(xl) {
            }
        }
        p{
            font-size: 16px;
            line-height: 28px;
            color: $white;
            @include ps-letter-tracking(10);
            @include media-breakpoint-up(md) {
                font-size: 14px;
                line-height: 22px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: 22px;
            }
            @include media-breakpoint-up(xl) {
            }
        } 
    }
    .col-md-4{        
        padding: 0 15px 19px;
        @include media-breakpoint-up(md) {
            padding: 0 20px 12px;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 30px 12px;
        }
        @include media-breakpoint-up(xl) {
        }
        @include media-breakpoint-down(sm) {
            max-width: 50%;
            flex: 0 0 50%;
        }
        a{
            &:hover{
                @include hover(){
                    text-decoration: none;
                }
                @include media-breakpoint-up(md) {
                    .icon{
                        background: none;
                        img{
                            &.normal{
                                opacity: 0;
                            }
                            &.hover{
                                opacity: 1;
                            }
                        }
                    }
                }               
            }
        }
        .icon{
            width: 72px;
            height: 72px;
            line-height: 68px;
            margin: 0 auto 17px;
            display: block;
            background-color: $white;
            position: relative;
            @extend .rounded-circle; 
            @include transition(all 350ms);                     
            img{
                display: inline-block;
                vertical-align: middle;
                @include transition(all 350ms);
                max-width: 45px;
                max-height: 45px;
                &.hover{
                    opacity: 0;
                    max-width: 60px;
                    @include position(absolute, 50%, auto, auto, 50%);
                    @include translate(translate(-50%, -50%));
                }
            }
        }
        h4{
            font-size: 15px;
            line-height: 20px;
            color: $white;
            font-weight: 700;
            text-transform: none;
            padding: 0 0 7px;
            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 10px;
            }
        }
        p{
            font-size: 13px;
            line-height: 18px;
            opacity: 0.7;
            color: $white;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }   
    .btn{
        color: $black;
        border-color: $white;
        background: $white;
        min-width: 142px;
        margin: 24px auto;
        @include box-shadow(0px 10px 12px rgba(0,0,0,0.14));
        @include hover(){
            text-decoration: none;
            background: $primary-color;
            border-color: $primary-color;
            color: $white;
        }
        @include media-breakpoint-down(sm) {
            padding: 14px 25px;
            font-size: 18px;
            line-height: 24px;
            min-width: 190px;
        }
    } 
}