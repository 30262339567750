
// Adaptation Bar
// -----------------------------------------------------------------------------

.adaptation-bar{
    padding: 45px 0 60px;
    overflow: hidden;
    position: relative;   
    @include media-breakpoint-up(md) {
        padding: 50px 0 70px;
    }
    @include media-breakpoint-up(lg) {
        padding: 67px 0 109px;
    } 
    &.issue{
        padding: 45px 0px;
        background: $neutral-lighter;
        @include media-breakpoint-up(md) {
            padding: 55px 0px;
        }
        @include media-breakpoint-up(lg) {
            padding: 75px 0px;
        }
        .multiple-btns {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .btn-wrap{
                margin-right: 10px;
            }
            a.btn{
                width: 100%;
            }
            a.btn.btn-primary {
                width: 100%;
            }
        }
        &.recent {
            background: $neutral-lighter;
            margin: -100px 0;
            padding: 150px 0 150px;
            @include media-breakpoint-up(lg) {
                margin: -133px 0;
                padding: 187px 0 187px;
            }
        }
    }
    &.more-info h2{
        margin: 0 auto;
        margin-bottom: 10px;
    }
    &.more-info a {
        color: $black;
        opacity: 0.7;
        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }
    &.more-info div {
        
        @include media-breakpoint-down(xs) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
        }
    }
    &:not(.issue){
        z-index: 1;
        &::before{
            @include oval(700px, $white);            
        }
    }
    .meta{
        font-size: 12px;
        line-height: 18px;
        color: $primary-color;
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        padding: 0 0 7px;
        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 20px;
        }
        @include media-breakpoint-up(lg) {
        }
        strong{
            font-weight: 500;
            text-transform: uppercase;
            color: $black;
        }
    }
    .btn{
        @include media-breakpoint-down(sm) {
            min-width: 220px;
        }
    }
    h2{
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        padding-bottom: 12px;
        @include media-breakpoint-up(md) {
            font-size: 36px;
            line-height: 40px;
            padding-bottom: 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 42px;
            line-height: 42px;
            padding-bottom: 22px;
        }
    }
    .row{
        margin: 0px;
        div[class|=col]{
            padding: 0 22px 0 0;
            &.image{                
                @include media-breakpoint-up(md) {
                    padding-right: 30px;
                }
                @include media-breakpoint-up(lg) {
                    padding-right: 80px;
                }
                img{
                    width: 100%;
                    border: 1px solid $neutral-lightest;
                }
            }
            &.text{
                padding: 30px 0 0 0px;
                @include media-breakpoint-up(md) {
                    padding: 0px 0 0 4px;
                }
                @include media-breakpoint-up(lg) {
                    padding: 13px 0 0 4px;
                }
            }
        }
    }
    .tag button {
        background: #f2653d;
        border: 2px solid #f2653d;
        color: #fff;
        font-weight: bold;
        padding: 0px 5px;
        margin: 5px;
    }
}