
// Blog List Bar
// -----------------------------------------------------------------------------

.bloglist-bar{
    padding: 20px 0 3px;
    background: $neutral-lighter;
    @include media-breakpoint-up(md) {
        padding: 40px 0 80px;
    }
    @include media-breakpoint-up(lg) {
        padding: 60px 0 80px;
    }
    .tag{
        font-size: 9px;
        line-height: 12px;
        color: $white;
        font-weight: 700;
        display: table;
        background: $secondary-color;
        text-transform: uppercase;
        @include ps-letter-tracking(120);
        padding: 4px 7px 2px;
        margin: 0 0 11px;
        &.yellow{
            background: $yellow-color;
        }
         &.green{
            background: $green-color;
        }
        &.red{
            background: $red-color;
        }
        &.blue{
            background: $secondary-color;
        }
        &.general-tag{
            background: $primary-color;
        }
    }
    h3:not(.widget-title){
        font-size: 16px;
        line-height: 18px;
        color: $black;
        padding: 0 0 3px;
        font-weight: 700;
        @include ps-letter-tracking(40);        
    }
    .meta{
        font-size: 10px;
        line-height: 14px;
        color: $neutral-darker;
        font-weight: 500;
        display: block;
        padding: 0 0 9px;
    }
    p{
        font-size: 14px;
        line-height: 22px;
        color: $neutral;
        @include ps-letter-tracking(20);
    }
    a{
        &.read-more{
            display: inline-block;
            font-size: 13px;
            font-weight: 600;
            color: $primary-color;
            text-decoration: underline;
            @include hover(){                    
                text-decoration: none;                    
            }
        }
    }
    .featured-post{
        margin: 0px 0px 15px;
        background: $white;
        [class|=col]{
            padding: 0px;
            margin: 0px;
            &.image{
                @include media-breakpoint-up(md) {
                    flex: 0 0 49.5%;
                    max-width: 49.5%;
                }
                img{
                    width: 100%;
                }
            }
            &.text{
                padding: 16px 20px 5px 20px;
                @include media-breakpoint-up(md) {
                    padding: 20px 15px 0;
                    flex: 0 0 50.5%;
                    max-width: 50.5%;
                }
                @include media-breakpoint-up(lg) {
                    padding: 25px 32px 0 24px;
                }
            }
        }
    }
    .left-part{
        @include media-breakpoint-up(md) {
            flex: 0 0 67.3%;
            max-width: 67.3%;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-down(sm) {
            padding: 0 15px 42px;
        }
        .ads{
            margin: 0 0 15px;
            @include media-breakpoint-down(sm) {
                margin:  0 -20px 15px;
            }
            img{
                width: 100%;
            }
        }
    }
    .sidebar{
        @include media-breakpoint-up(md) {
            flex: 0 0 32.7%;
            max-width: 32.7%;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-down(sm) {
            padding: 44px 20px;
            background: $white;
            margin: 0 -5px;  
            width: calc(100% + 10px);          
        }
    }
    .post{
        margin: 0; 
        @include media-breakpoint-up(md) {
            margin: 0px 0px 15px; 
        }       
        [class|=col]{
            padding: 0px;
            margin: 0px 0 15px;
            background: $white;
            @include media-breakpoint-up(md) {
                flex: 0 0 48.7%;
                max-width: 48.7%;
                margin: 0px;
            }
            &:nth-of-type(2n){
                margin-left: auto !important;                              
            }
            img{
                width: 100%;
            }
            
            .card-bg-img {
                height: 13em;
                background-size: cover;
                background-position: top;
            }
            
            .text{ 
                padding: 16px 20px 5px 20px;  
                @include media-breakpoint-up(md) {
                    padding: 12px 15px 15px;  
                }
                @include media-breakpoint-up(lg) {
                    padding: 16px 23px 15px;  
                }             
            }
        } 
    }
    .load-more{
        display: block;
        font-size: 16px;
        line-height: 20px;
        background: $white;
        text-transform: uppercase;
        text-decoration: none;
        color: $black;
        font-weight: 700;
        text-align: center;
        padding: 15px 20px;
        @include hover(){                    
            text-decoration: none;  
            background: $primary-color;
            color: $white;                  
        }
    }  
    .author-cta a {
        margin-bottom: 30px;
    }
    .author-cta a.btn {
        margin-right: 0;
        min-width: 0!important;
    }
}