
// Header Navigation
// -----------------------------------------------------------------------------
$dropshadows: 0 15px 15px -15px rgba(0,0,0,0.15), inset 0 15px 15px -15px rgba(0,0,0,0.15);
.header-cntr .navbar-nav {
    width: 75%;
    padding: 24px 0 0;
    justify-content: space-between;
    @include transition(all 350ms);
    @extend .ml-auto;
    @extend .d-flex;
    @extend .flex-row; 
    @include media-breakpoint-down(sm){ 
        display: none !important;
    }
    @include media-breakpoint-up(md) {
        width: 80%;
    } 
    @include media-breakpoint-up(xl) {
        width: 75%;
    } 
    li{
        font-size: 17px;
        line-height: 22px;
        font-weight: 600;
        float: left;
        //padding: 0 0 0px 48px;
        position: static;
        &.dropdown-sm {
            position: relative;
            .dropdown-menu {
                width: calc(100% + 140px);
                position: absolute;
                left: -20px;
                padding: 30px 40px;
                &:before, &:after {
                    display: none;
                }
                @include media-breakpoint-up(md){
                    padding: 40px;
                    left: -20px!important;
                }
                @include media-breakpoint-up(lg){
                    left: -8px!important;
                } 
            }
        }
        &:last-child .dropdown-menu {
            left: auto!important;
            right: -50px;
            @include media-breakpoint-up(md){
                right: -60px;
            }
        }

        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 19px;
            padding: 0 0 0 10px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 17px;
            line-height: 22px;
            margin-right: 8px;
        }
        &:first-child{
            padding-left: 0px;
        }
        &.show {
            a:not(.btn){
                color: $primary-color;
                @include transition(all 350ms);
                &:before{
                    width: 146px;
                    @include media-breakpoint-up(md) {
                        width: 110px;
                    }
                    @include media-breakpoint-up(lg) {
                        width: 146px;
                    }
                }
                &.dropdown-toggle{
                    &:after{
                        content: fa-content($fa-var-chevron-up); 
                    }
                }
            }            
        } 
        a:not(.btn){
            color: $black;
            display: inline-block;
            vertical-align: top; 
            padding-bottom: 29px;
            position: relative;
            @include media-breakpoint-up(md) {
                padding-bottom: 20px;
            }
            @include media-breakpoint-up(lg) {
                padding-bottom: 29px;
            }
            @include hover(){
                text-decoration: none;
                color: $primary-color;
            }  
            &:before{
                content: "";
                height: 4px;
                width: 0px;
                margin-left: -73px;
                background: $primary-color;
                display: block;
                @include position(absolute, auto, auto, 0px, 50%); 
                @include media-breakpoint-up(md){
                    margin-left: -55px;
                }
                @include media-breakpoint-up(lg){
                    margin-left: -73px;
                }               
            }         
            &.dropdown-toggle{
                padding: 0px 10px 29px 0;
                @include media-breakpoint-up(md) {
                    padding: 0px 10px 20px 0;
                }
                @include media-breakpoint-up(lg) {
                    padding: 0px 10px 29px 0;
                }
                &:after{
                    margin: 1px 0 0 5px;
                    display: inline-block;
                    vertical-align: top;
                    font-size: 10px;  
                }
            }            
        }
    }
    .dropdown-menu{
        @include position(absolute, 100% !important, auto, auto, -20px !important);
        @include border-radius(0px);
        @include box-shadow($dropshadows);    
        @include translate(none !important);             
        margin: 0px;
        padding: 0px;
        border: 0px;
        width: calc(100% + 35px);
        background-color: $neutral-lighter;
        &:before, &:after{
            content: "";
            width: 25000px;
            height: 100%;
            background: $neutral-lighter;  
            @include box-shadow($dropshadows);         
            @include position(absolute, 0px, auto, auto, -24985px);
        }
        &:after{
            @include position(absolute, 0px, -24985px, auto, auto);
        }
        .left{
            padding: 35px 54px 20px 141px;
            width: 42.4%;
            float: left;
            @include media-breakpoint-up(md){
                padding: 50px 30px 20px 20px;
                width: 36.4%;
            }
            @include media-breakpoint-up(lg){
                padding: 40px 40px 20px 30px;
                width: 34%;
            } 
            @include media-breakpoint-up(xl){
                padding: 35px 54px 20px 141px;
                width: 42.4%;
            }            
        }
        .center{
            padding: 35px 50px 0px 0px;
            width: 30.5%;
            float: left; 
            @include media-breakpoint-up(md){
                padding: 50px 30px 0px 0px;
                width: 30%;
            }
            @include media-breakpoint-up(lg){
                padding: 40px 40px 0px 0px;
                width: 30%;
            } 
            @include media-breakpoint-up(xl){
                padding: 35px 50px 0px 0px;
                width: 30.5%;
            }             
        }
        .mura-object-content {
            height: 100%;
        }
        .widget {
            padding: 75px 20px 80px 20px;
            display: flex;
            flex-direction: column;
            background: $primary-light-color;
            position: relative;
            @include box-shadow(inset 0 15px 15px -15px rgba(0,0,0,0.15));
            @include media-breakpoint-up(md){
                min-height: 490px;
                height: 100%;
                width: 215px;
            }
            @include media-breakpoint-up(lg){
                width: 275px;
                padding-right: 0;
            }
            &:after{
                content: "";
                width: 25000px;
                height: 100%;
                z-index: 1;
                @include box-shadow($dropshadows);         
                @include position(absolute, 0px, -24970px, auto, auto);                
            }
            img{
                width: 150px;
                @include media-breakpoint-up(lg){
                    width: 215px;
                    padding-right: 0;
                }
            }
            p{
                font-size: 16px;
                line-height: 20px;
                color: $white;
                font-style: italic;
                font-weight: 400;
                margin: 0 0px 10px 0;
                font-family:  $open-sans-family;
                position: relative;
                z-index: 2;
            }
            .cta-subtitle {
                margin-top: 28px;
            }
            h3{
                font-size: 17px;
                line-height: 23px;
                padding: 0;
                font-weight: 700;
                color: $white;
                text-transform: uppercase;
                font-weight: 700;
                font-family:  $open-sans-family;
                position: relative;
                z-index: 2;
                @include media-breakpoint-up(md){
                    font-size: 17px;
                    line-height: 23px;
                }
                @include media-breakpoint-up(lg){
                    font-size: 17px;
                    line-height: 23px;
                }
            }
            .btn{
                border-color: $white;  
                min-width: 138px;
                background: none;
                margin: 16px 0 0;
                color: $white;
                position: relative;
                z-index: 2;
                @include hover(){ 
                    color: $primary-color;
                    background: $white; 
                    text-decoration: none; 
                }
            }
        }
        ul{
            padding: 0px;
            margin: 0px;
            list-style-type: none;
            li{
                font-size: 13px;
                line-height: 18px;
                border:0;
                float: none;
                padding: 0px;
                margin: 0px;
                text-transform: uppercase;
                a:not(.btn){
                    color: $black;
                    font-weight: 700;
                    text-decoration: none;
                    padding: 0px;
                    &:before{
                        display: none;
                    }
                    &:hover {
                        color: $primary-color;
                    }
                }
                &:last-child{
                    ul{
                        padding-bottom: 0px;
                    }
                }
                ul{
                    border-top: 2px solid $black;
                    margin: 8px 0 0;
                    padding: 12px 0 28px;
                    li{
                        border-bottom: 1px solid rgba(0,0,0,0.15);
                        text-transform: none;
                        &:last-of-type{
                            border: 0px;
                        }
                        a:not(.btn){
                            font-weight: 400;
                            padding: 6px 0;
                            @include hover(){
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.header-cntr.header-fixed .top-links {
    @include media-breakpoint-down(md){
        display: none;
    }
}

.header-cntr.header-fixed .navbar-nav {
    justify-content: space-between;
    margin-right: 40px;
    .dropdown-menu {
        &#SchoolArtsDropdown > div {
            margin-left: 52.5%;
            @include media-breakpoint-up(lg){
                margin-left: 50%;
            }
            @include media-breakpoint-up(xl){
                margin-left: 47%;
            }
        }
    }
}

#Products {
    &.show {
        display: flex;
    }
    div > ul > li {
        cursor: pointer;
        margin-bottom: 10px;
        
        ul {
            border-top: none;
            margin-top: 0;
            padding-top: 5px;
        }
        
        > a {
            display: block;
            width: 100%;
            padding: 8px 0;
            border-bottom: 2px solid $black;
            position: relative;
        
            &::after {
                content: '\f067';
                font-family: 'Font Awesome 5 Free';
                line-height: 1;
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
                -webkit-transform: translate(0, -50%);
            }
        }
        
        &.active {
            
            > a::after {
                content: '\f068';
            }
            .nav {
                display: block;
            }
        }
        
        .nav {
            display: none;
        }
    }
}