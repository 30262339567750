
// Slider Bar
// -----------------------------------------------------------------------------
.slider-bar{
    display: none;
    padding: 0px;    
    overflow: hidden;
    clear: both;   
    &.slick-initialized {
        display: block;
    }
    .slide{        
        background: $primary-color;
        margin: 0px; 
        overflow: hidden; 
        position: relative;           
        .text{
            text-align: center;
            margin-left: 0px;
            width: 100%; 
            height: auto;
            @extend .d-flex;
            @extend .align-items-center;
            @include media-breakpoint-up(md){
                margin-left: 0px;
                width: 45%;
                height: 350px;  
            }
            @include media-breakpoint-up(lg){
                margin-left: 0px;
                width: 40%;  
                height: 456px;
            }
            @include media-breakpoint-up(xl){
                margin-left: -165px;
                width: 50%; 
            }
            .inner{
                margin: 0 auto;
                padding: 30px 40px;
                max-width: 435px; 
                @include media-breakpoint-up(md){
                    padding: 15px 40px;
                    margin: 0 0 0 auto !important;
                }
                @include media-breakpoint-up(lg){
                    padding: 15px 57px;
                }  
                @include media-breakpoint-up(xl){
                    padding: 15px 70px 15px 0px;
                }             
            }           
            h1{
                color: $white;
                font-size: 35px;
                line-height: 40px;
                color: $white;
                font-weight: 500;
                padding-bottom: 13px;
                @include media-breakpoint-up(md){
                    font-size: 36px;
                    line-height: 40px;
                    padding-bottom: 10px;
                }
                @include media-breakpoint-up(lg){
                    font-size: 40px;
                    line-height: 44px;
                    padding-bottom: 20px;
                }
                @include media-breakpoint-up(xl){
                    font-size: 45px;
                    line-height: 50px;                    
                }
            }
            p{
                font-size: 16px;
                line-height: 24px;
                color: $white;
                font-weight: 400;
                @include media-breakpoint-up(md){
                    font-size: 14px;
                    line-height: 22px;  
                }
                @include media-breakpoint-up(lg){
                    font-size: 16px;
                    line-height: 27px;  
                }
            }
        }
        a {
            border: 2px solid $white;
            font-weight: bold;
            background: none;
            color: $white;
            min-width: 172px;
            padding: 12px 20px;
            margin: 3px auto 5px;
            font-size: 15px;
            line-height: 18px;
            display: inline-block;
            &:hover {
                background: $white;
                color: $primary-color;
                text-decoration: none;
            }
            @include media-breakpoint-up(md){
                min-width: 150px;
                padding: 12px 20px;
                margin: 0px auto;
                font-size: 16px;
                line-height: 20px;
            }
            @include media-breakpoint-up(lg){
                min-width: 182px;
                padding: 12px 20px;
                margin: 15px auto 20px;
            }
        }
        .image{
            background-position:  top center;
            background-repeat: no-repeat;
            background-size: cover;            
            width: 100%;
            float: right;
            height: 200px;
            margin-left: 0px;            
            @include media-breakpoint-up(md) {
                width: 55%;                
                height: 350px;  
                @include position(absolute, 0px, auto, auto, 45%); 
            }
            @include media-breakpoint-up(lg) {
                width: 60%;
                left: 40%;
                height: 456px;
            }
            @include media-breakpoint-up(xl) {
                width: 63.7%;
                margin-left: -165px;
                left: 50%;
                height: 456px;
            }          
        }
    }
}