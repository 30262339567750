
// Sponsor Bar
// -----------------------------------------------------------------------------

.sponsor-bar{    
    background: $secondary-color;
    padding: 0px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 55px 0 60px;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    @include media-breakpoint-up(lg) {
        padding: 82px 0 80px;
    }
    h2{
        color: $white;
        font-size: 31px;
        line-height: 35px;
        font-weight: 500; 
        padding: 0 0px 20px;   
        @include media-breakpoint-down(sm) {
            font-size: 26px;
            line-height: 30px;
            padding: 0 0px 15px;   
        }    
    }
    p{
        font-size: 14px;
        line-height: 24px;
        color: $white;
    }
    .text{
        max-width: 100%;
        text-align: center;
        padding-top: 40px;
        @include media-breakpoint-up(md) {
            max-width: 380px;
            padding: 0 0 0 43px;
            text-align: left;
        }
        @include media-breakpoint-up(lg) {
            padding: 0px 0 0 25px;
            max-width: 550px;
        }
        @include media-breakpoint-up(xl) {
            padding: 0px;
        }
    }
    .btn{
        color: $black;
        background: $white;
        border-color: $white;
        margin: 12px 4px;        
        padding: 10px 25px;
        &.btn-primary{
            background: none;
            color: $white;
            @include hover(){
                color: $black;
                background: $white;
                border-color: $white;
            } 
        }
        @include media-breakpoint-down(sm) {
            margin: 5px 0px;
        }
    }
    .issue-sponsor-logo{
        display: flex;
        justify-content: center;
        img {
            width: 100%;
            max-width: 400px;
            padding: 40px 0px;
            @include media-breakpoint-up(md) {
                padding: 0 40px;
            }
        }
    }
}
.issue-sponsor-fullad {
    margin: 50px auto 0;
}