
// Archive Bar
// -----------------------------------------------------------------------------
.archive-bar{
    padding: 15px 0 80px;
    @include media-breakpoint-down(xs) {
        .archive-filter {
            text-align: center;
            flex-direction: column;
            align-items: center!important;
            form {
                margin-bottom: 0!important;
            }
        }
    }
    &.issue{
        padding: 45px 0 30px;
        @include media-breakpoint-up(md) {
            padding: 40px 0 60px;
        }
        @include media-breakpoint-up(lg) {
            padding: 75px 0 111px;
        }
        .meta{
            text-transform: uppercase;
        }
        h3{
            font-size: 27px;
            line-height: 32px;
            padding: 0 0 11px;
        }
        p{
            margin-bottom: 0;
        }
        .row{
            margin: 0px;
            padding: 30px 0 0;
            @include box-shadow(0px 22px 34px rgba(0,0,0,0));
            @include media-breakpoint-up(md) {
                padding: 45px 0 0;
            }
            @include media-breakpoint-up(lg) {
                padding: 61px 0 0;
            }
            &:nth-of-type(2n){            
                div[class|=col]{
                    padding: 0px;
                    &.image{                          
                        @extend .order-2;  
                        padding: 0px;  
                        @include media-breakpoint-down(sm) {
                            order: 0 !important;
                        }                                 
                    }
                    &.text{
                        padding: 30px 0px 15px;
                        @include media-breakpoint-up(md) {
                            padding: 4px 30px 0 0px;
                        }
                        @include media-breakpoint-up(lg) {
                            padding: 4px 76px 0 0px;
                        }
                    }
                }
            }
            div[class|=col]{
                padding: 0;
                &.image{    
                    @extend .order-0; 
                    padding: 0px; 
                    max-height: 300px;
                    min-height: 250px;
                    overflow: hidden;
                    position: relative;
                    @include media-breakpoint-down(sm) {
                        order: 0 !important;
                    }                                    
                    img{
                        width: 100%;
                        position: absolute;
                        margin: auto;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }
                &.text{
                    padding: 30px 0px 15px;
                    @include media-breakpoint-up(md) {
                        padding: 0px 0 0 30px;
                    }
                    @include media-breakpoint-up(lg) {
                        padding: 0px 0 0 96px;
                    }
                }
            }
        }
    }
    form{
        @extend .mb-3;
        margin: 0;
        label{
            @extend .mr-auto;
            font-weight: 500;
            display: block;
            padding: 9px 0 0;
            margin: 0px;
        }
        .dropdown-toggle{
            min-width: 160px;
            background: $white;
            color: $black;
            border: 1px solid $neutral-light;
            position: relative;
            padding: 12px 20px 12px 0;
            margin-top: 5px;
            &:after {
                position: absolute;
                right: 25px;
            }
        }
        .dropdown-menu {
            width: 100%;
            margin: 0;
            border-radius: 0;
        }
    }
    .meta{
        display: block;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        color: $primary-color;
        padding-bottom: 0;
    }
    h2{
        font-size: 30px;
        line-height: 34px;
        padding: 0px;
        text-align: center;
        font-weight: 400;
        @include media-breakpoint-up(md) {
            font-size: 44px;
            line-height: 48px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 48px;
            line-height: 52px;
        }
        &.year-page {
            margin: 40px 0 60px;
        }
    }
    h3{
        font-size: 26px;
        line-height: 30px;
        font-weight: 600;
        padding: 0 0 8px;
        @include media-breakpoint-up(md) {
            font-size: 30px;
            line-height: 36px;
        }
        @include media-breakpoint-up(lg) {
        }
    }
    p{
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 19px;
        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 19px;
        }
    }
    .btn{
        margin: 0px;
        margin-top: 20px;
        &.btn-primary{
            min-width: 161px;
            padding: 10px 20px;
        }
    }
    article.row{
        margin: 20px 0 0;
        padding: 0;
        background: $white;
        @include box-shadow(0px 22px 34px rgba(0,0,0,0.06));
        &:nth-of-type(2n){            
            div[class|=col]{
                padding: 0px;
                &.image{                    
                    @extend .order-0; 
                    @include media-breakpoint-down(sm) {
                        order: 0 !important;
                    } 
                    @include media-breakpoint-up(md) {
                        padding: 0;  
                    }
                    @include media-breakpoint-up(lg) {
                        padding: 0 25px 0 0;  
                    }           
                    img{
                        width: 100%;
                    }
                }
                &.text{
                    padding: 30px 20px;
                    @include media-breakpoint-up(md) {
                        padding: 22px 30px 25px;
                    }
                    @include media-breakpoint-up(lg) {
                        padding: 30px 40px 30px 15px;
                    }
                }
            }
        }
        div[class|=col]{
            padding: 0;
            &.image{ 
                @extend .order-2;
                @include media-breakpoint-down(sm) {
                    order: 0 !important;
                }
                @include media-breakpoint-up(md) {
                    padding-left: 0px;
                }
                @include media-breakpoint-up(lg) {
                    padding-left: 25px;
                }
                img{
                    width: 100%;
                }
            }
            &.text{
                padding: 30px 20px;
                @include media-breakpoint-up(md) {
                    padding: 26px 30px 25px;
                }
                @include media-breakpoint-up(lg) {
                    padding: 30px 15px 30px 40px;
                }
            }
        }
        &.magazine-featured {
            display: flex;
            flex-direction: row-reverse;
            .image {
                padding-left: 0px!important;
            }
        }
    }
}