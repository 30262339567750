
// Latest Education Bar
// -----------------------------------------------------------------------------

.latesteducation-bar{
    padding: 193px 0px 46px; 
    margin-top: -120px; 
    background: $neutral-lighter;
    @include media-breakpoint-up(md) {
        padding: 130px 0px 33px; 
        margin-top: -70px; 
    }
    @include media-breakpoint-up(lg) {
        padding: 193px 0px 33px; 
        margin-top: -120px; 
    }
    h2{
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;        
        padding: 0  0 34px;
        text-align: center; 
        @include media-breakpoint-up(md) {
            font-size: 45px;
            line-height: 50px;
            padding: 0  0 25px;
            font-weight: 400;
        }
        @include media-breakpoint-up(lg) {
            font-size: 54px;
            line-height: 58px;
            padding: 0  0 34px;
        }
    }
    .category{        
        @extend .d-flex;
        @extend .flex-wrap;
        padding: 0 0 11px;
        span{
            font-size: 10px;
            line-height: 14px;
            color: $white;
            padding: 4px 7px 1px;
            background: $primary-color;
            text-transform: uppercase;
            margin: 0 2px 2px 0;
            @include ps-letter-tracking(20);
            font-weight: 700;     
            @include media-breakpoint-down(sm) {
                font-size: 12px;
                line-height: 16px;
            }       
            &.yellow{
                background: $yellow-color;
            }
            &.blue{
                background: $secondary-color;
            }
            &.dark-green{
                background: $green-dark-color;
            }
        }
    }
    h4{
        font-size: 15px;
        line-height: 18px;
        color: $black;
        font-weight: 700;        
        text-transform: none;
        padding: 0 0 4px;
        @include media-breakpoint-down(sm) {
            font-size: 21px;
            line-height: 24px;
        }
    }
    .meta{
        display: block;
        font-size: 10px;
        line-height: 14px;
        font-weight: 500;
        color: $neutral-darker;
        padding: 0 0 11px;
        @include media-breakpoint-down(sm) {
            font-size: 11px;
            line-height: 15px;
            padding: 0 0 14px;
        }
    }
    p{
        font-size: 13px;
        line-height: 23px;
        color: $black;
        font-weight: 400;
        margin: 0px;
        padding: 0 10px 0 0;
        @include media-breakpoint-down(sm) {
            font-size: 14px;
            line-height: 24px;
            padding: 0px;
        }
        .read-more{
            text-decoration: underline;
            @include hover(){
                text-decoration: none;
            }
        }
    }
    .inner{
        background: $white;
        padding: 30px 30px 24px;
        height: 100%;
        @include transition(all 350ms);
        @include media-breakpoint-up(md) {
            padding: 15px 15px 15px;
        }
        @include media-breakpoint-up(lg) {
            padding: 22px 22px 18px;
        }
        @include media-breakpoint-down(sm) {
            @include box-shadow(0px 10px 14px rgba(0,0,0,0.03));
            &:hover{
                @include box-shadow(0px 10px 14px rgba(0,0,0,0.03));
            }
        }
        &:hover{
            @include box-shadow(0px 0px 12px rgba(0,0,0,0.25));
        }
        .image{
            margin: 0 0 18px;
            img {
                width: 100%;
                height: 150px!important;
                object-fit: cover;
            }
        }
    }
    .row{
        margin: 0px -10px;        
        div[class|=col]{
            padding: 0px 10px 20px;  
            margin-bottom: 20px;
            @include media-breakpoint-down(sm) {
                padding: 0px 10px 0px; 
            }        
        }
    }
    .slick-dots{
        position: relative;
        top: auto;
        padding: 12px 0 0;
    }
    .slick-dots li button:before{
        border: 1px solid rgba(0,0,0,0.3);
        background: none;
    }
    .slick-dots li.slick-active button:before{
        border-color: $primary-color;
        background: $primary-color;
    }
    .slick-prev, .slick-next{
        right: -53px;
        margin-top: -65px;
        background-color: #e0e0e0;
    }
    .slick-prev{
        left: -53px;
    }
}