
// Filter Bar
// -----------------------------------------------------------------------------

.filter-bar{
    padding: 0px;
    position: relative;
    .inner{
        @extend .row;
        margin: 0px;
    }
    .label{
        font-size: 12px;
        line-height: 18px;
        color: $black;
        font-weight: 700;
        border-bottom: 3px solid $primary-color;
        padding: 22px 0 17px;
        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 18px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 18px;
        }
    }
    .filter-list{
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        @extend .d-inline-flex;
        @include media-breakpoint-down(sm) {
            display: flex!important;
            justify-content: space-between;
            width: 100%;
        }
        .active {
            &.blogs a {
                border-bottom: 3px solid $primary-color;
                color: $primary-color;
                font-weight: bold;
            }
            &.davis-blog a {
                border-bottom: 3px solid $green-color;
                color: $green-color;
                font-weight: bold;
            }
            &.schoolarts-room a {
                border-bottom: 3px solid $secondary-color;
                color: $secondary-color;
                font-weight: bold;
            }
            &.curators-corner a {
                border-bottom: 3px solid $yellow-color;
                color: $yellow-color;
                font-weight: bold;
            }
            &.articles a {
                border-bottom: 3px solid $red-color;
                color: $red-color;
                font-weight: bold;
            }
        }
        li {
            font-size: 10px; 
            line-height: 18px;
            color: $black;
            padding: 22px 0 0 31px;
            @include media-breakpoint-up(md) {
                font-size: 12px;
                line-height: 18px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 14px;
                line-height: 18px;
            }
            @include media-breakpoint-down(sm) {
                padding-left: 10px;
            }
            &:first-child {
                padding-left: 0;
            }
            a {
                display: inline-block;
                padding: 0 0 12px 0;
                vertical-align: top;
                color: $black;
                text-decoration: none;
                border-color: $white;
                line-height: 1.2;
                &.nav-link{
                    &:after{
                        margin: 0 0 0 10px;
                        font-size: 10px;
                        vertical-align: top;
                    }
                }
            }
            &:nth-child(1) a:hover {
                border-bottom: 3px solid $primary-color;
            }
            &:nth-child(2) a:hover {
                border-bottom: 3px solid $green-color;
            }
            &:nth-child(3) a:hover {
                border-bottom: 3px solid $secondary-color;
            }
            &:nth-child(4) a:hover {
                border-bottom: 3px solid $yellow-color;
            }
            &:nth-child(5) a:hover {
                border-bottom: 3px solid $red-color;
            }
        }
    }
    form{
        margin-left: auto !important;
        position: relative;
        @extend .d-flex;
        @extend .align-items-center;
        @include media-breakpoint-down(sm) {
            @include position(absolute, 11px, 20px, auto, auto);
        }
        input[type="search"]{
            border: 0px;
            margin: 0px;
            width: 100%;
            padding: 0px 10px 0 0;
            display: block;
            font-size: 12px;
            line-height: inherit;
            color: $black;
            height: 40px;            
        }
        button{
            padding: 0px;
            border: 0px;
            font-size: 16px;
            line-height: inherit;
            color: $black;
            background: none;
            height: 40px;
            cursor: pointer;  
            @include hover(){
                color: $primary-color;
            }         
        }
    }
}