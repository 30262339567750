
// Contact Bar
// -----------------------------------------------------------------------------

.contact-bar{
    padding: 51px 0 0;      
    position: relative;
    float: left;
    width: 100%;
    @include media-breakpoint-up(md) {
        padding: 40px 0 0;  
    }
    @include media-breakpoint-up(lg) {
        padding: 51px 0 0;  
    }
    .contact-bg{        
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        @include position(absolute, 0px, auto, auto, 0px);
        &::before{
            @include oval(860px, $neutral-lighter);
            min-height: 1023px;
            top: auto;
            bottom: 0px;
        }
    }    
    .container{
        position: relative;
        z-index: 2;
        padding-bottom: 65px;
    }
    h2{
        font-size: 30px;
        line-height: 34px;
        color: $black;
        font-weight: 300;
        text-align: center;
        padding: 0 0 25px;
        @include media-breakpoint-up(md) {
            font-size: 50px;
            line-height: 54px;
            padding: 0 0 30px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 60px;
            line-height: 64px;
            padding: 0 0 50px;
        }
    }
    h5 {
        text-align: center;
        padding: 15px 0px 5px 0px;
    }
    .heading{
        @extend .d-flex;        
        @extend .align-self-center;
        background: $primary-color;
        height: 70px;
        padding: 7px 25px 7px;
        @include media-breakpoint-up(md) {
            padding: 7px 25px 7px;
            height: 70px;
        }
        @include media-breakpoint-up(lg) {
            padding: 7px 32px 7px 33px;
            height: 87px;
        }
        &.yellow{
            background: $yellow-color;
        }
        &.green{
            background: $green-color;
        }
        h3{ 
            color: $white;
            padding: 0px;
            font-size: 22px;
            line-height: 26px;
            font-weight: 500;   
            margin: auto auto auto 0px !important;
            @include media-breakpoint-up(md) {
                font-size: 25px;
                line-height: 30px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 29px;
                ine-height: 32px;
            }
        }
        img{
            margin: auto 0px auto auto !important;
            max-height: 40px;
            @include media-breakpoint-up(md) {
                max-height: 40px;
            }
            @include media-breakpoint-up(lg) {
                max-height: inherit;
            }
        }
        i {
            align-self: center;
            color: $white;
            font-size: 40px;
        }
    }    
    address{
        font-size: 14px;
        line-height: 22px;
        color: $black;
        font-weight: 400;
        font-style: normal;
        margin-bottom: 7px;
        strong{
            font-weight: 700;
            display: block;
        }
        a{
            color: $black;
            
        }
        a[href^="tel"]{
            text-decoration: none;
            cursor: text;
        }
    }
    label{
        font-size: 14px;
        line-height: 22px;
        color: $black;
        font-weight: 700;
        font-style: normal;
        margin-bottom: 8px;
        display: block;
    }
    .text{
        padding: 30px 25px;
        border-right: 1px solid rgba(0,0,0,0.1);
        min-height: inherit;
        @include media-breakpoint-up(md) {
            padding: 22px 25px 20px;
            min-height: 275px;
        }
        @include media-breakpoint-up(lg) {
            padding: 28px 36px 20px;
        }
        a {
            color: $black;
            text-decoration: none;
        }
    }
    .row{
        margin: 0px 0 -87px;
        background: $white;
        @include box-shadow(0 50px 30px -30px rgba(0,0,0,0.02));
       .col{
            padding: 0px;  
            @include media-breakpoint-up(md) {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }             
            &:nth-last-of-type(1){
                .text{
                    border: 0px; 
                }
            }
        }
    }
    .bootstrap-select .dropdown-toggle .filter-option{
        text-align: left;
    }
    .bootstrap-select>.dropdown-toggle{
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 11px 24px 7px 15px;
    }
    .dropdown-toggle:after{
        font-size: 14px;
    }
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn){
        @include media-breakpoint-up(md) {
            width: 185px;
        }
        @include media-breakpoint-up(lg) {
            width: 220px;
        }
    }
    .btn-primary, .btn-primary:hover{
    color: #white;
    background-color: $orange;
    border-color: $orange;
    padding: 5px;
    }
    .paging-container {
        text-align: right;
    }
    form {
        margin-top: 15px;
        div {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
        }
    }
    .col-md-4.col-xs-12.contact-cards {
        padding-left: 0px;
        padding-right: 0px;
        p {
            margin-bottom: 0;
        }
        @include media-breakpoint-down(md) {
            .text {
                border-right: 0;
            }
        }
    }
}