
// Resource Bar
// -----------------------------------------------------------------------------

.resource-bar{
    padding: 57px 0 150px;
    overflow: hidden;
    position: relative;
    margin: 0 0 -120px;
    @include media-breakpoint-up(md) {
        padding: 47px 0 40px;
        margin: 0px;
    }
    @include media-breakpoint-up(lg) {
        padding: 57px 0 75px;
    }
    &::before{
        @include oval(500px, $neutral-lighter);
        min-height: 580px;
        @include media-breakpoint-up(md) {
            min-height: 400px;
            @include oval(200px, $neutral-lighter);
        }
        @include media-breakpoint-up(lg) {
            min-height: 580px;
        }
    }  
    .container{
        position: relative;
    }
    h2{
        font-size: 26px;
        line-height: 30px;
        color: $black;
        font-weight: 500;
        text-align: center;
        padding: 0 0 25px;
        @include media-breakpoint-up(md) {
            font-size: 26px;
            line-height: 30px;
            padding: 0 0 25px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 30px;
            line-height: 36px;
            padding: 0 0 35px;
        }
    }
    ul{
        padding: 0px;
        margin: 0px -11px;
        list-style-type: none;
        @extend .row;
        @include media-breakpoint-up(md) {
            margin: 0px -7px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0px -11px;
        }
        li{
            max-width: 100%;
            flex: 0 0 100%; 
            padding: 0 11px 22px;
            @include media-breakpoint-up(sm) {
                padding: 0 7px 14px;
                max-width: 50%;
                flex: 0 0 50%; 
            }
            @include media-breakpoint-up(md) {
                padding: 0 7px 14px;
                max-width: 33.3333333%;
                flex: 0 0 33.3333333%; 
            }
            @include media-breakpoint-up(lg) {
                padding: 0 11px 22px;
            }
            a{
                height: 65px;
                background: $white;
                font-size: 14px;
                line-height: 18px;
                color: $black;
                font-weight: 600;
                @extend .d-flex;        
                @extend .align-self-center;
                @include box-shadow(0px 12px 12px rgba(0,0,0,0.04));
                @include hover(){
                    color: $primary-color;
                    text-decoration: none;                    
                } 
                @include media-breakpoint-up(md) {
                    font-size: 14px;
                    line-height: 18px;
                    height: 65px;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 15px;
                    line-height: 20px;
                    height: 72px;
                }
                @include hover(){
                    text-decoration: none;
                }
                span{
                    margin: auto !important;
                    text-align: center;
                    padding: 0 7px;
                }
            }
        }               
    }
}