
@mixin img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  border: 0px;
}

@mixin btn-solid {
    background: $button-background-color;
  	border: 2px solid $button-border-color;
  	border-radius: 0;
  	color: $button-font-color;
  	display: inline-block;
  	font-family: $core-font-family;
  	font-size: 14px;
  	font-weight: 700;	
  	line-height: 18px;
  	margin: 8px 0;
  	outline: 0;
  	padding: 10px 25px;
  	position: relative;
  	text-align: center;
  	text-decoration: none;	
  	min-width: 180px;
  	-webkit-appearance: none;
  	@include media-breakpoint-up(md) {
  		min-width: 215px;
  	}
  	@include hover() {
  		background: $button-background-color-hover;
  		border-color: $button-border-color-hover;
  		color: $button-font-color-hover;
  		text-decoration: none;
  		@include box-shadow(none);				
  	}
}

@mixin btn-border {
  @include btn-solid;
  background: none;
	border-color: $button-secondary-border-color;
	color: $button-secondary-font-color;
	@include hover() {
		background: $button-secondary-background-color-hover;
		border-color: $button-secondary-border-color-hover;
		color: $button-secondary-font-color-hover;
		@include box-shadow(none);
	}
}

@mixin arrow($direction, $color, $size) {
  content: '';
  display: block;
  height: 0;
  width: 0;
  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
  @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }
  @else if $direction == 'bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  }
  @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  }
  @else if $direction == 'top-left' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
  }
  @else if $direction == 'top-right' {
    border-top: $size solid $color;
    border-left: $size solid transparent;
  }
  @else if $direction == 'bottom-left' {
    border-bottom: $size solid $color;
    border-right: $size solid transparent;
  }
  @else if $direction == 'bottom-right' {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
  }
}

@mixin hover() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin ps-letter-tracking($amount) {
  letter-spacing:$amount / 1000 + em;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin translate($content) {
          transform:   $content;
  -webkit-transform:   $content;
     -moz-transform:   $content;
       -o-transform:   $content;
      -ms-transform:   $content;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow:  $shadow;
     -moz-box-shadow:  $shadow;
      -ms-box-shadow:  $shadow;
       -o-box-shadow:  $shadow;
          box-shadow:  $shadow;
}

@mixin border-radius($content) {
  -webkit-border-radius:  $content;
     -moz-border-radius:  $content;
      -ms-border-radius:  $content;
       -o-border-radius:  $content;
          border-radius:  $content;
}

@mixin transition($transition) {
  -webkit-transition:  $transition;
     -moz-transition:  $transition;
      -ms-transition:  $transition;
       -o-transition:  $transition;
          transition:  $transition;
}

@mixin oval($width, $color) {
  content: '';
  display: block;
  height: 100%;
  width: calc(100% + #{$width});
  background: $color;  
  @include position(absolute, 0px, auto, auto, -($width/2)); 
  @include border-radius(50%); 
}