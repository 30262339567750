
// Category Bar
// -----------------------------------------------------------------------------

.category-bar{
    padding: 202px 0px 150px;
    margin: -133px 0 0;
    background: $neutral-lighter;    
    @include media-breakpoint-up(md) {
        margin: -100px 0 0;
        padding: 145px 0px 40px; 
    }
    @include media-breakpoint-up(lg) {
        margin: -133px 0 0;
        padding: 202px 0px 40px; 
    }
    @include media-breakpoint-up(xl) {
    }     
    .container{
        position: relative;
        z-index: 2;
    }
    .info{
        max-width: 650px;
        margin: 0 auto;
        text-align: center;
        padding: 0 0 10px;
        @include media-breakpoint-up(md) {
            padding: 0 0 20px;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 0 30px;
        }
        h2{
            font-size: 30px;
            line-height: 36px;
            font-weight: 400;
            color: $black;
            padding: 0  0 9px;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                line-height: 50px;
                padding: 0  0 10px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 54px;
                line-height: 58px;
                padding: 0  0 20px;
            }
            @include media-breakpoint-up(xl) {
            }
        }
        p{
            font-size: 16px;
            line-height: 27px;
            @include ps-letter-tracking(10);
            @include media-breakpoint-up(md) {
                font-size: 14px;
                line-height: 22px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: 22px;
            }
            @include media-breakpoint-up(xl) {
            }
        } 
    }
    .image{
        height: 191px;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        background-color: $primary-color; 
        display: none;
        @include transition(all 350ms);
        @include media-breakpoint-up(md) {
            height: 160px;
            display: block;
        }
        @include media-breakpoint-up(lg) {
            height: 191px;
        }
        @include media-breakpoint-up(xl) {
        }             
    }
    .yellow{
        h4{
            background: $yellow-color; 
        } 
        .link{
            color: $yellow-color;
        }  
    }
    .green{
        h4{
            background: $green-color;
        }
        .link{
            color: $green-color;
        }
    }
    .col-md-6{        
        .image{
            background-color: $green-dark-color;
            background-size: cover !important;
            height: 20px;
            position: relative;   
            @include media-breakpoint-up(md) {
                height: 270px;
            }
            @include media-breakpoint-up(lg) {
            }
            @include media-breakpoint-up(xl) {
            }         
        }
        h3{ 
            font-size: 20px;
            line-height: 26px;
            color: $white;
            font-weight: 500;            
            background: $green-dark-color;
            padding: 5px 22px;
            @extend .d-flex;
            @extend .align-items-center;
            @include media-breakpoint-up(md) {
                font-size: 27px;
                line-height: 33px;
                @include position(absolute, 50%, 52px, auto, auto);
                @include translate(translateY(-50%));
                background: none;
                max-width: 150px;
                padding: 0px 0 15px;
            }
            @include media-breakpoint-down(sm) {
                height: 54px;
            }
            &.active:after{
                content: fa-content($fa-var-chevron-up);
            }
            &:after{
                @extend .fas;
                content: fa-content($fa-var-chevron-down);
                @include position(absolute, 50%, 20px, auto, auto);
                @include translate(translateY(-50%));
                border: 0px;
                font-size: 19px;
                line-height: 24px;
                display: block;
                margin: 0 0 0 10px;
                @include media-breakpoint-up(md) {
                    display: none
                }
            }
        }
        .text{
            padding: 21px 25px 29px;              
            @include media-breakpoint-up(md) {
                padding: 30px;
                border-top: 1px solid rgba(0,0,0,0.1);
                display: block !important;
            }
            @include media-breakpoint-up(lg) {
                padding: 40px 50px;
            }
            @include media-breakpoint-up(xl) {
            }      
            @include media-breakpoint-down(sm) {
                display: none;
            }    
            p{
                font-size: 16px;
                line-height: 27px;
                margin: 0 0 6px;
                @include media-breakpoint-up(md) {
                    font-size: 13px;
                    line-height: 27px;
                    margin: 0 0 6px;
                }
                @include media-breakpoint-up(lg) {
                }
                @include media-breakpoint-up(xl) {
                }
            }  
        }
        .link{
            color: $green-dark-color;
        }
    }
    h4{
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: $white;
        background: $primary-color;
        height: 54px;
        text-transform: none;
        padding: 5px 22px;
        position: relative;
        @extend .d-flex;
        @extend .align-items-center;
        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 28px;
            padding: 5px 20px;
            height: 76px;
            font-weight: 600;
        }
        @include media-breakpoint-up(lg) {
            font-size: 28px;
            line-height: 32px;
            padding: 5px 36px;
            height: 86px;
        }
        @include media-breakpoint-up(xl) {
        }
        a, a:hover, a:active {
           color: $white;
           text-decoration: none;
        }
        &.active:after{
            content: fa-content($fa-var-chevron-up);
        }
        &:after{
            @extend .fas;
            content: fa-content($fa-var-chevron-down);
            @include position(absolute, 50%, 20px, auto, auto);
            @include translate(translateY(-50%));
            border: 0px;
            font-size: 19px;
            line-height: 24px;
            display: block;
            margin: 0 0 0 10px;
            @include media-breakpoint-up(md) {
                display: none
            }
        }
    }
    .col-md-4{
        &:nth-of-type(1){
            .text{
                border-left: 0px; 
            }
        }
        &:nth-of-type(2){
            .image, h4 {
                background-color: $yellow-color;
            }
            .link {
                color: $yellow-color;
            }
        }
        &:nth-of-type(3){
            .image, h4 {
                background-color: $green-color;
            }
            .link {
                color: $green-color;
            }
        }
        &:hover{
            .image{
                background-blend-mode: normal;
            }
        }
        .text{
            padding: 21px 25px 29px;               
            @include media-breakpoint-up(md) {
                padding: 25px 20px;
                border-left: 1px solid rgba(0,0,0,0.1);
                display: block !important;
            }
            @include media-breakpoint-up(lg) {
                padding: 31px 37px 33px;
            }
            @include media-breakpoint-up(xl) {
            }  
            @include media-breakpoint-down(sm) {
                display: none;
            }       
            p{
                font-size: 16px;
                line-height: 27px;
                margin: 0 0 18px;
                @include media-breakpoint-up(md) {
                    font-size: 13px;
                    line-height: 27px;
                    margin: 0 0 18px;
                }
            }  
        }
    }
    .link{
        font-size: 17px;
        line-height: 27px;
        font-weight: 700;
        display: table;
        position: relative;
        text-decoration: underline;
        color: $primary-color;
        @include hover(){
            text-decoration: none;
        }
        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 27px;
        }
        &:after {
            @extend .fas;
            content: fa-content($fa-var-chevron-right);
            position: absolute;
            top: 7px;
            right: -15px;
        }
    }  
    .row{
        margin: 0px;
        background: $white;
        div[class|=col]{
            padding: 0px;     
            display: flex;
            flex-direction: column;
        }
        &:last-child {
            margin-top: 20px;
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
            .image { 
                height: 150px; 
                @include media-breakpoint-up(md) {
                    height: 270px;
                }
            }
            .image, .col-md-6 .text, div[class|=col] {
                display: block!important;
            }
            .text h2 {
                color: $green-dark-color;
                font-size: 20px;
                line-height: 1.2;
                 @include media-breakpoint-up(md) {
                    font-weight: 600;
                    padding-bottom: 5px;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 23px;
                }
            }
            .image-text {
                position: absolute;
                right: 30px;
                bottom: 30px;
                @include media-breakpoint-up(lg) {
                    right: 40px;
                    bottom: 57px;
                }
                h2 {
                    font-size: 25px;
                    line-height: 1.2;
                    padding-bottom: 10px;
                    color: $white;
                    text-align: right;
                    @include media-breakpoint-up(md) {
                        font-weight: 600;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 23px;
                    }
                }
                a.link {
                    color: $white;
                    float: right;
                    right: 15px;
                }
            }
        }
    }
    
}