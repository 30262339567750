
// Educator Bar
// -----------------------------------------------------------------------------

.marketing-bar{
    text-align: center;    
    margin: 0px;
    padding: 75px 0 30px;
    position: relative;
    @include media-breakpoint-up(md) {
        padding: 110px 0 30px; 
    }
    @include media-breakpoint-up(lg) {
        padding: 104px 0 47px;
    }
    &::before{
        @include oval(640px, $white); 
        @include media-breakpoint-up(md) {
            @include oval(512px, $white);
        }
        @include media-breakpoint-up(lg) {
            @include oval(280px, $white);
        } 
        @include media-breakpoint-up(xl) {
            @include oval(512px, $white);
        }        
    }
    &.subscribe-schoolarts {
        padding: 61px 0px 81px;
        margin: 0px
    }
    .container{
        position: relative;
    }
    .card-wrap {
        margin: 5px 0px;
    }
    .card{
        background: $white;
        border: 1px solid $neutral-lighter;
        @include box-shadow(0px 22px 34px rgba(0,0,0,0.06));
        border-radius: 0px;
        height: 100%;
        div{
            &:first-child{
                margin-top: 10px;
            }
        }
        h3 {
            color: $core-font-color;
        }
        p {
            color: $core-font-color;
            font-size: 14px;
            margin: 0px;
        }
        .btn.btn-primary {
            background: none;
            color: $primary-color;
            border-color: $primary-color;
            border-width: 2px;
            &:hover {
                color: $white;
                background: $primary-color;
                border-color: $primary-color;
            }
        }
        .card-body {
            padding: 10px;
        }
    }
    h2{
        color: $white;
        font-size: 28px;
        line-height: 32px;
        font-weight: 500; 
        padding: 0 0px 15px;  
        @include media-breakpoint-up(md) {
            font-size: 32px;
            line-height: 36px;
            padding: 0 80px 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 36px;
            line-height: 43px;
            padding: 0 106px 21px;
        }     
    }
    p{
        font-size: 16px;
        line-height: 24px;
        color: $white;
        @include media-breakpoint-up(md) {
            font-size: 15px;
            line-height: 22px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 17px;
            line-height: 27px;
        }
    }
    .text{
        max-width: 680px;
        margin: 0 auto;
    }
    .btn{
        color: $black;
        background: $white;
        border-color: $white;
        margin: 10px 4px;
        border-width: 1px;
        padding: 11px 25px;   
        @include hover() {
            background: none;
            color: $white;
            @include box-shadow(none);				
        }  
        @include media-breakpoint-down(sm) {
            min-width: 200px;
            padding: 11px 15px;
        }
        &.btn-primary{
            background: none;
            color: $white;
            @include hover() {
                background: $white;
                color: $black;
                border-color: $white;
                @include box-shadow(none);				
            } 
        }
    }
}

.marketing-main {
    .page-banner .text {
        h1 {
            font-weight: 500;
            padding: 10px 0;
            line-height: 1.2;
        }
        p {
            margin: 0;
        }
        @include media-breakpoint-up(md) {
            h1 {
                font-size: 33px;
                padding-top: 0;
            }
            p {
                font-size: 17px;
            }
        }
    }
    .educator-bar {
        margin: 0;
        z-index: 1;
        &::before{
            @include oval(640px, $white); 
            @include media-breakpoint-up(md) {
                @include oval(512px, $white);
            }
            @include media-breakpoint-up(lg) {
                @include oval(280px, $white);
            } 
            @include media-breakpoint-up(xl) {
                @include oval(512px, $white);
            }        
        }
        p {
            color: $core-font-color;
            font-size: 15px;
        }
        .btn {
            border-width: 2px;
            &.btn-primary {
                background: none;
                color: $secondary-color;
                border-color: $secondary-color;
                @include hover() {
                    background: $secondary-color;
                    color: $white;
                    @include box-shadow(none);				
                } 
            }
        }
    }
    .adaptation-bar {
        .btn.btn-primary {
            background-color: none;
            &:hover {
                background-color: $primary-color;
            }
        }
        .tag .meta { 
            color: $core-font-color; 
            display: inline-block;
            background-color: $neutral-lightest;
            padding: 2px 8px;
            margin-bottom: 4px;
            font-weight: 500;
            font-size: 10px;
        }
        @include media-breakpoint-up(lg) {
            .row div[class|=col].image { padding-right: 50px; }
            .row div[class|=col].text { padding-top: 0; }
        }
        &.issue.more-info {
            background: none;
            padding-top: 20px;
        }
    }
    
}