.subscribe-pg {
     h1 { font-size: 40px; line-height: 1.3; }
    .summary {
        p { text-align: center; }
        a { margin-right: 0; font-style: normal; }
    }
    .sub-packages > div {
        background: $neutral-lighter;
        margin-bottom: 20px;
        padding: 20px;
        text-align: center;
        h2 { 
            font-size: 28px;
            font-weight: 600;
            line-height: 1.3;
            span { 
                display: block;
                text-transform: uppercase;
                font-size: 13px;
                line-height: 1;
            }
        }
        h4 {
            font-size: 22px;
            line-height: 1.2;
        }
        h5 {
            font-size: 16px;
            font-style: italic;
        }
        &:first-child {
            border: 2px solid $neutral-light;
            background: none;

         }
    }
    .sub-packages {
        @include media-breakpoint-up(md) {
            display: flex;
            margin-top: 20px;
            &>div:nth-child(1) { order: 2; margin-top: -20px; margin-bottom: 0; }
            &>div:nth-child(2) { order: 3; }
            &>div:nth-child(3) { order: 1; }
        }
    }
} 