
// Educator Bar
// -----------------------------------------------------------------------------

.educator-bar{
    text-align: center;    
    padding: 130px 0 59px;
    margin: -80px 0 0;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 130px 0 59px;
        margin: -80px 0 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 165px 0 59px;
        margin: -100px 0 0;
    }
    &::before{
        @include oval(300px, $secondary-color);
        @include border-radius(0px 0px 50% 50%);
    }
    &.subscribe-schoolarts {
        text-align: center;
        padding: 40px 0 30px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        @include media-breakpoint-up(md) {
            padding: 40px 0;
        }
        @include media-breakpoint-up(lg) {
            padding: 60px 0;
        }
        .multiple-btns {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .btn-wrap {
                margin-right: 10px;
            }
            a.btn.btn-primary {
                width: 100%;
            }
        }
    }
    .container{
        position: relative;
    }
    h2{
        color: $white;
        font-size: 28px;
        line-height: 32px;
        font-weight: 500; 
        padding: 0 0px 15px;  
        @include media-breakpoint-up(md) {
            font-size: 32px;
            line-height: 36px;
            padding: 0 80px 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 36px;
            line-height: 43px;
            padding: 0 106px 21px;
        }     
    }
    p{
        font-size: 16px;
        line-height: 24px;
        color: $white;
        
        /* Commented out per client request
        @include media-breakpoint-up(md) {
            font-size: 15px;
            line-height: 22px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 17px;
            line-height: 27px;
            
           
        }
        */
    }
    .text{
        max-width: 680px;
        margin: 0 auto;
    }
    .btn{
        color: $black;
        background: $white;
        border-color: $white;
        margin: 10px 4px;
        border-width: 1px;
        padding: 11px 25px;   
        @include hover() {
            background: none;
            color: $white;
            @include box-shadow(none);				
        }  
        @include media-breakpoint-down(sm) {
            min-width: 200px;
            padding: 11px 15px;
        }
        &.btn-primary{
            background: none;
            color: $white;
            @include hover() {
                background: $white;
                color: $black;
                border-color: $white;
                @include box-shadow(none);				
            } 
        }
        &.btn-secondary{
            background: $secondary-color;
            color: $white;
            border-color: $secondary-color;
            @include hover() {
                background: $white;
                color: $secondary-color;
                @include box-shadow(none);				
            }
        }
        &.btn-third{
            background: $white;
            color: $secondary-color;
            border-color: $white;
            @include hover() {
                background: $secondary-color;
                color: $white;
                @include box-shadow(none);				
            }
        }
    }
}