
// Intro Bar
// -----------------------------------------------------------------------------

.intro-bar{
    padding: 55px 0px 38px;    
    text-align: center;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 45px 0px 60px;
    }
    @include media-breakpoint-up(lg) {
        padding: 61px 0px 81px;
    }
    &::before{
        @include oval(612px, $white);
        min-height: 1023px;
        top: auto;
        bottom: 0px;
        @include media-breakpoint-up(md) {
            min-height: 800px;
        }
        @include media-breakpoint-up(lg) {
            min-height: 1023px;
        }
        @include media-breakpoint-up(xl) {
            min-height: 770px;
        }
    }
    .info{
        max-width: 626px;
        margin: 0 auto;
        position: relative;        
    }
    h2{
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;
        color: $black;
        padding: 0  0 14px;
        br{
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        @include media-breakpoint-up(md) {
            font-size: 32px;
            line-height: 36px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 36px;
            line-height: 43px;
        }
    }
    p{
        font-size: 16px;
        line-height: 28px;
        padding: 0 20px;        
        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
            padding: 0px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 27px;
        }
    }
}