
@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

html body {
	background: #fff;
	color: $core-font-color;
	font-family: $core-font-family;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
	-webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $secondary-font-color;
	font-family: $secondary-font-family;
	font-weight: 500;	
	margin: 0;
	padding: 0 0 15px;
	a {
		color: $secondary-font-color;
	}
}

h1 {
	font-size: 54px;
	line-height: 58px;
}

h2 {
	font-size: 36px;
	line-height: 42px;	
}

h3 {
	font-size: 30px;
	line-height: 41px;	
}

h4 {
	font-family: $core-font-family;	
	font-weight: 600;
	@include ps-letter-tracking(25);
	font-size: 27px;
	line-height: 33px;
	text-transform: uppercase;	
}

p {
	line-height: inherit;
	margin: 0 0 20px 0;
}

a {
	color: $link-color;
	text-decoration: none;
	&:hover {
		color: $link-color-hover;
		text-decoration: underline;
	}
}

ol, ul {
	margin-left: 2em;
}

 header ol, header ul {
	margin-left: 0;
}

.dropdown-toggle{
	&:after{
		@extend .fas;
		content: fa-content($fa-var-chevron-down);
		border: 0px;
		font-size: 12px;
		line-height: inherit;
		vertical-align: text-top;
	}
	.dropup & {
		&:after{
			@extend .fas;
			content: fa-content($fa-var-chevron-up);
			border: 0px;
			font-size: 12px;
			line-height: inherit;
		}
	}
}

.play-btn{
	@include position(absolute, 0px, auto, auto, 0px);
	display: flex;
	width: 100%;
	height: 100%;
	font-size: 0px;
	line-height: 0px;
	&:before{
		content: "";
		@include position(absolute, 50%, auto, auto, 50%);
		width: 57px;
		height: 57px;
		margin: -28px 0 0 -28px;
		background: $white url(#{$assetPath}/play-btn.png) 50% 50% no-repeat;
		@extend .rounded-circle;
	}
}