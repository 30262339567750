
// CTA Bar
// -----------------------------------------------------------------------------

.daviscta-bar{   
    .inner{
        background: $white;
        text-align: center;
        margin: -100px 0 0;
        padding: 35px 30px 40px;
        position: relative;
        @include box-shadow(0 80px 100px -30px rgba(0,0,0,0.06));
        @include media-breakpoint-up(md) {
            margin: -152px 0 0;
            padding: 52px 40px 50px;
        }
        @include media-breakpoint-up(lg) {
        }
    }    
    h2{
        font-size: 26px;
        line-height: 30px;
        color: $black;
        padding: 0 0 15px;
        @include media-breakpoint-up(md) {
            font-size: 26px;
            line-height: 30px;
            padding: 0 0 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 30px;
            line-height: 34px;
            padding: 0 0 19px;
        }
    }
    .btn{
        margin: 9px 0 0;
        min-width: 200px;
        padding: 13px 25px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;  
        @include media-breakpoint-up(md) {
            min-width: 200px;
            padding: 12px 22px;
        }
        @include media-breakpoint-up(lg) {
            min-width: 237px;
            padding: 13px 25px;
        }      
    }
}