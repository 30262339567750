
// One Column Template
// -----------------------------------------------------------------------------


.standard-heading{
    padding: 60px 0 20px 0;
    h1 { 
        text-align: center;
        font-weight: 300;
    }
}
.standard-content {
    margin-bottom: 70px;
    .summary {
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding-bottom: 15px;
        margin-bottom: 35px;
    }
    ul {
      list-style: none;
      margin-left: 20px;
    }
    
    ul li {
    	margin-bottom: 5px;
    }
    
    ul li:before {
      content: "\2022";
      color: $primary-color;
      font-weight: 900;
      display: inline-block; 
      font-size: 18px;
      width: 1em;
      margin-left: -1em;
    }
}