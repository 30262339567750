
// Base Colors
// -----------------------------------------------------------------------------

$primary-color:    #f2653d;
$secondary-color:  #27427e;
$yellow-color:     #fdb117;
$green-color:      #89bb3f;
$green-dark-color: #1a998b;
$red-color:        #d01f3a;

$neutral-darkest:  #111111;
$neutral-darker:   #777777;
$neutral-dark:     #393939;
$neutral:          #666666;
$neutral-light:    #cccccc;
$neutral-lighter:  #f2f2f2;
$neutral-lightest: #e6e6e6;
$primary-dark-color: #af4922;
$primary-light-color: #ec542d;
$back-to-top-color: #8c8c8c;

$assetPath : "../images";

$black:  #000000;
$white:  #FFFFFF;

// Base Typography
// -----------------------------------------------------------------------------

$core-font-family:   'Raleway', sans-serif;
$core-font-color:    $black;

$secondary-font-family: $core-font-family;
$secondary-font-color:  $black;

$open-sans-family: 'Open Sans', sans-serif;

$link-color:            $primary-color;
$link-color-hover:      $secondary-color;

// Buttons
// -----------------------------------------------------------------------------

$button-font-color                       : $white;
$button-border-color                     : $primary-color;
$button-background-color                 : $primary-color;
$button-font-color-hover                 : $primary-color;
$button-border-color-hover               : $primary-color;
$button-background-color-hover           : none;

$button-secondary-font-color             : $primary-color;
$button-secondary-border-color           : $primary-color;
$button-secondary-background-color       : $white;
$button-secondary-font-color-hover       : $white;
$button-secondary-border-color-hover     : $primary-color;
$button-secondary-background-color-hover : $primary-color;

// Form Styles
// -----------------------------------------------------------------------------

$form-input-border-color: $neutral-light;
$form-input-font-color:   $core-font-color;
$form-error-color:        #790000; 

.far, .fal {
  font-family: 'Font Awesome 5 Pro';
}