
// School Art Bar
// -----------------------------------------------------------------------------

.schoolart-bar{
    padding: 64px 0px 30px;   
    @include media-breakpoint-up(md) {
        padding: 50px 0px 50px;   
    }
    @include media-breakpoint-up(lg) {
        padding: 68px 0px 78px;   
    }    
    h2{
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;        
        padding: 0  0 33px;
        text-align: center;
        @include media-breakpoint-up(md) {
            font-size: 45px;
            line-height: 50px;
            font-weight: 400;   
        }
        @include media-breakpoint-up(lg) {
            font-size: 54px;
            line-height: 58px;
        }        
    }
    h4{
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        text-transform: none;
        padding: 0 0 10px;
        @include media-breakpoint-up(md) {
            font-size: 13px;
            line-height: 18px;
            padding: 0 0 4px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 15px;
            line-height: 20px;
        }
        @include media-breakpoint-up(xl) {
        }
    }
    .image{
        float: left;
        width: 40.5%;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        img{
            width: 100%;
        }
    }
    .meta{
        font-size: 13px;
        line-height: 18px;
        display: block;
        color: $primary-color;
        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 16px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 18px;
        }
    }
    .post{
        padding: 0px 0 16px;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            padding: 0px 0 16px;
        }
        @include media-breakpoint-up(lg) {
            padding: 0px 0 16px 7px;
        }
        @include media-breakpoint-down(sm) {
            width: 46%;
            float: left;
            &:nth-of-type(2){
                float: right;
            }
            &:nth-of-type(3){
                display: none;
            }
        }
        img{
            margin: 0px 0px 22px;
            float: none;
            width: 100%;
            @include media-breakpoint-up(md) {
                margin: 4px 15px 0 0px;
                float: left;
                width: 76px;
            }
            @include media-breakpoint-up(lg) {
                margin: 4px 20px 0 0px;
            }
        }
    }
    .text{
        float: left;
        width: 100%;
        padding: 30px 0px 35px;
        margin: 0;
        @include media-breakpoint-up(md) {
            padding: 0 30px;
            width: 59.5%;
            margin: -6px 0 0;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 50px 0 33px;
        }
        h4{
            font-size: 27px;
            line-height: 32px;            
            color: $black;
            padding: 0 0 8px;  
            @include media-breakpoint-down(sm) {
                font-size: 22px;
                line-height: 26px;
            }          
        }
        p{
            margin: 0 0 10px;
            font-size: 13px;
            line-height: 1.7;
            @include media-breakpoint-down(sm) {
                font-size: 15px;
                line-height: 24px;
            }
        }
        .meta{
            font-size: 16px;
            line-height: 20px;
            padding: 0 0 13px;
            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        .btn{
            margin: 20px 0 0;
            min-width: 191px;
            @include media-breakpoint-up(md) {
                min-width: 160px;
            }
            @include media-breakpoint-up(lg) {
                min-width: 191px;
            }
        }
    }
    .row{
        margin: 0px;
        background: $white;
        div[class|=col]{
            padding: 0px;           
        }
    }
}