
// Comment Bar
// -----------------------------------------------------------------------------

.comment-bar{
  padding: 44px 0 56px;  
  @include media-breakpoint-up(md) {
    padding: 40px 0;    
  }
  @include media-breakpoint-up(lg) {
    padding: 67px 0 72px;
  }
  h2{
      font-size: 24px;
      line-height: 30px;
      color: $black;
      font-weight: 600;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 28px;
        line-height: 32px;
        text-align: left;
      }
      @include media-breakpoint-up(lg) {
        font-size: 30px;
        line-height: 34px;
      }
      @include media-breakpoint-down(sm) {
          padding: 0 0 12px;
      }
  }
  .panel{
      @extend .row;
      padding: 10px 0;
      margin: 0px;
      position: relative;
      .image{          
          @include position(absolute, 10px, 0px, auto, auto);
          img{
              @extend .rounded-circle;
          }
          @include media-breakpoint-down(sm) {
              @include position(absolute, auto, 9px, 22px, auto);
              width: 42px;
              z-index:  1;
          }
      }
      .post-description{
          background: $neutral-lighter;
          padding: 20px 28px 23px 25px;
          max-width: 100%;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          margin-right: 0px;
          position: relative;
          @include media-breakpoint-up(md) {
            max-width: 77.5%;
            margin-right: 54px;
            padding: 25px 28px 19px 37px;
          }
          @include media-breakpoint-up(lg) {
            max-width: 82.5%;
          }  
          @include media-breakpoint-up(md) {
            &:after{
                @include arrow(top-left, $neutral-lighter, 20px);
                @include position(absolute, 43px, -29px, auto, auto);
                border-top-width: 24px;
                border-right-width: 29px;
            }
          }
          p{
              font-size: 13px;
              line-height: 24px;
              color: $black;
              font-style: italic;
              @include media-breakpoint-down(sm) {
                  margin: 0 0 17px;
              }
          }
          .meta{
              font-size: 12px;
              line-height: 16px;
              font-weight: 600;
              color: $black;
              display: block;
              text-align: right;
              @include media-breakpoint-down(sm) {
                  padding: 0 33px 0 0;
              }
          }
      }
   }
   form{
       padding: 34px 0 0;
       @include media-breakpoint-down(sm) {
            padding: 23px 0 0;
       }
       label{
           display: block;
           font-size: 13px;
           line-height: 16px;
           color: $black;
           font-weight: 600;
           margin: 0 0 16px;
       }
       .row{
           margin: 0px;
       }
       textarea{
           border: 1px solid $form-input-border-color;
           padding: 18px 25px;
           height: 128px;
           font-size: 13px;
           line-height: 18px;
           color: $black;
           resize: none;
           display:  block;
           width: 100%;
           max-width: 100%;
           margin: 0 0 20px;
           -webkit-appearance: none;
           @include border-radius(0px);
           @include media-breakpoint-up(md) {
             max-width: 77.5%;
           }
           @include media-breakpoint-up(lg) {
            max-width: 82.5%;
           }  
       }
       .btn{
           min-width: inherit;
           padding: 0px;
           width: 119px;
           height: 41px;
           margin: 0 0 0 auto !important;
           text-transform: uppercase;
           font-weight: 600;
           -webkit-appearance: none;
           @include media-breakpoint-down(sm) {
             margin: 0 auto !important;
           }
       }
   }
}