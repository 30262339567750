
// Customer Bar
// -----------------------------------------------------------------------------

.customer-bar{
    padding: 100px 0 10px;
    clear: both;
    text-align: center;
    @include media-breakpoint-up(md) {
        padding: 110px 0 40px;
        text-align: left;
    }
    @include media-breakpoint-up(lg) {
        padding: 100px 0 75px;
    }
    h2{
        font-size: 30px;
        line-height: 34px;
        font-weight: 400;
        text-align: center;
        padding: 0 0 30px;
        @include media-breakpoint-up(md) {
            font-size: 45px;
            line-height: 50px;
            padding: 0 0 30px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 54px;
            line-height: 58px;
            padding: 0 0 39px;
        }
    }
    h3 {
        color: $black;
        text-align: center;
    }
    .col-md-3:nth-child(2){
        .icon{
            background: $yellow-color;
        }
        h4{
            color: $yellow-color;
        }
    }
    .col-md-3:nth-child(3){
        .icon{
            background: $green-color;
        }
        h4{
            color: $green-color;
        }
    }
    .col-md-3:nth-child(4){
        .icon{
            background: $green-dark-color;
        }
        h4{
            color: $green-dark-color;
        }
    }
    .icon{
        width: 51px;
        height: 51px;
        margin: 0 auto 14px;
        justify-content: center;
        @extend .rounded-circle;
        @extend .d-flex;
        @extend .align-items-center;        
        background: $primary-color;  
        @include media-breakpoint-up(md) {
            margin: 0 0 14px;
        }
        @include media-breakpoint-up(lg) {
        }      
        img{
            margin: auto !important;
        }
        i {
            display: flex;
            justify-content: center;
            color: white;
            font-size: 25px;
        }
    }
    h4{
        font-size: 15px;
        line-height: 20px;
        font-weight: 700;
        color: $primary-color;
        text-transform: uppercase;
        padding: 0 0 12px;   
        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 18px;
            padding: 0 0 15px;   
        }
        @include media-breakpoint-up(lg) {
            font-size: 15px;
            line-height: 20px;
            padding: 0 0 22px;   
        }     
    }
    .col-md-3{
        @include media-breakpoint-down(sm) {
            padding-bottom: 25px;
        }
    }
    address{
        font-size: 14px;
        line-height: 21px;
        color: $black;
        margin: 0 0 12px;
        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 18px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 21px;
        }
        a{
           color: $black;
        }
        strong{
            font-weight: 700;
            display: block;
        }
    }
    .local-reps {
        display: flex;
        justify-content: center;
    }
    .repesentative-wrap {
        display: flex;
        justify-content: center;
    }
    .repesentative-tile {
        padding-bottom: 10px;
        p {
            margin: 0;
        }
    }
    .dropdown-menu {
        max-height: 200px;
        overflow: scroll;
        width: 92%;
    }
    button.btn.dropdown-toggle {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    &.sales-reps {
        padding: 55px;
        padding-bottom: 0;
    }
}