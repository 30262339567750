
// Page Banner
// -----------------------------------------------------------------------------

.page-banner {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 200px;
	padding: 30px 0px;
	text-align: center;	
	position: relative;
	@extend .d-flex;
	@extend .align-items-center;
	@include media-breakpoint-up(md) {
		height: 285px;
		padding: 30px 0px;	
	}
	@include media-breakpoint-up(lg) {
		height: 300px;
		padding: 38px 0px;	
	}
	&:after {
		content: ""; // ::before and ::after both require content
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background-color: $black;
	    opacity: 0.3;
	}
	& > * {
	    z-index: 100;
	}
	&.products h1 strong {
		font-size: 14px;
	    line-height: 27px;
	    display: block;
	    font-weight: 600;
	    text-transform: uppercase;
	    letter-spacing: .7em;
	}
	&.blog{
		padding: 0;
		height: auto;	
		@include media-breakpoint-up(md) {
			padding: 40px 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 52px 0 54px;
		}	
	}
	.text{
		background: $primary-color;
		max-width: inherit;
		margin:  0 -20px;
		padding: 26px 20px 37px;
		@include media-breakpoint-up(md) {
			max-width: 540px;
			margin:  0 auto;
			padding: 26px 20px;
		}
		@include media-breakpoint-up(lg) {
			max-width: 620px;
		}
		h1{
			font-size: 25px;
			line-height: 24px;
			font-weight: 400;
			padding: 0 0 21px;
			@include media-breakpoint-up(md) {
				font-size: 26px;
				line-height: 30px;
			}
			@include media-breakpoint-up(lg) {
				font-size: 30px;
				line-height: 30px;
			}
		}
		.tag{
			display: block;
			font-size: 12px;
			line-height: 16px;
			color: $white;
			text-transform: uppercase;
			font-weight: 700;
			@include ps-letter-tracking(600);
			padding: 0 0 11px;
		}
		.btn{
			border-color: $white;
			color: $white;
			background: none; 
			min-width: inherit;
			margin: 0px;
			padding: 7px 22px 8px;
			@include hover(){
				text-decoration: none;
				background: $white;
				color: $primary-color;
            }
			@include media-breakpoint-down(sm) {
				text-transform: uppercase;
			}
		}
		p {
			color: $white;
		}
	}
	h1 {
		color: $white;				
		font-size: 40px;
		font-weight: 300;
		line-height: 44px;
		margin: 0px;
		padding: 0px;	
		@include ps-letter-tracking(80);
		@include media-breakpoint-up(md) {
			font-size: 60px;			
			line-height: 64px;
		}
		@include media-breakpoint-up(lg) {
			font-size: 66px;			
			line-height: 70px;
		}
	}
	.crumblink{
		font-size: 14px;
		line-height: 27px;
		color: $white;
		display: block;
		font-weight: 600;
		text-transform: uppercase;
		@include ps-letter-tracking(700);
		@include hover(){
			text-decoration: none;
			color: $primary-color;
		}
	}
}
