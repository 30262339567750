
// Info Bar
// -----------------------------------------------------------------------------

.info-bar{
    text-align: center;
    padding: 40px 0 30px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    @include media-breakpoint-up(md) {
        padding: 40px 0 60px;
    }
    @include media-breakpoint-up(lg) {
        padding: 58px 0 90px;
    }
    &::before{
        @include oval(612px, $white);
        min-height: 1023px;
        top: auto;
        bottom: 0px;
        @include media-breakpoint-up(md) {
            min-height: 770px;
        }
        @include media-breakpoint-up(lg) {
            min-height: 1023px;
        }
        @include media-breakpoint-up(xl) {
            min-height: 770px;
        }
    }
    .container{
        position: relative;
    }
    p{
        font-size: 16px;
        line-height: 24px;
    }
}

.national-state-standards {
    &.info-bar {
        padding-top: 0;
    }
    ul {
        text-align: left;
        margin: 0;
    }
    li a {
        padding-left: 0;
        line-height: 20px;
    }
    button.btn {
        margin: 0;
    }
    .text-left {
        margin-top: 20px;
        h4 {
            text-align: center;
            text-transform: none;
            font-weight: 600;
            font-size: 23px;
        }
        h5 {
            padding: 10px 0 10px;
        }
        a {
            display: block;
            line-height: 1.3;
            font-size: 13px;
        }
    }
    .d-flex>div {
        padding: 5px;
        @include media-breakpoint-down(md) {
            flex: 1 0 45%;
        }
        @include media-breakpoint-down(xs) {
            flex: 1 0 100%;
        }	        
    }
}