
// State Adoption Resources
// -----------------------------------------------------------------------------

.us-states {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 50px;
    .state {
        flex: 1;
        flex-basis: auto;
    }
    img {
        max-width: 100px;
        max-height: 100px;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
    .btn {
        margin: 10px 10px 50px;
        min-width: 130px;
        background: none;
        border-color: $primary-color;
        color: $primary-color;
        &:hover {
            background: $primary-color;
            color: $white;
        }
        @include media-breakpoint-up(md) { min-width: 160px; }
        @include media-breakpoint-up(lg) { min-width: 190px; }
    }
}
.info-bar.no-padding {
    padding-top: 0;
}