
// feature Bar
// -----------------------------------------------------------------------------

.features-bar{    
    padding: 140px 0 120px;
    margin: -90px 0 0;
    text-align: center;
    position: relative;   
    overflow: hidden; 
    @include media-breakpoint-up(md) {
        padding: 150px 0 198px;
        margin: -90px 0 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 220px 0 217px;
        margin: -133px 0 0;
    }
    &::before{
        @include oval(620px, $secondary-color);
        @include border-radius(0px 0px 50% 50%);
    }
    button {
        background: none;
        border: none;
        &:hover {
            cursor: pointer;
        }
    }
    .row{
        padding: 0 20px;
        align-items: flex-start;
    }
    figure{
        width: 70px;
        height: 70px;
        background: $white;  
        padding: 10px;
        margin: 0 auto 12px;     
        @extend .rounded-circle; 
        @extend .align-items-center;
        @extend .d-flex;  
        @include media-breakpoint-up(md) {
            width: 70px;
            height: 70px;
            margin: 0 auto 12px;
        }
        @include media-breakpoint-up(lg) {
            width: 96px;
            height: 96px;
            padding: 0px;
            margin: 0 auto 28px; 
        }   
        img{
            display: block;
            margin:  0 auto;
            max-width: 55px;
            @include media-breakpoint-down(md) {
                max-width: 40px;
            }
        }  
    }
    h3{
        font-size: 16px;
        line-height: 20px;
        color: $white;
        font-weight: 700;
        padding: 0 0px 25px;
        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 20px;
            padding: 0px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 22px;            
        }
    } 
    .col{
        @include media-breakpoint-down(sm) {
            min-width: 130px;
        }
    }
}