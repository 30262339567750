// State Adoption Resources
// -----------------------------------------------------------------------------

.job-listing {
    text-align: center;
    margin: 50px 0;
    .top-content {
        margin-bottom: 30px;
        h3 {
            font-size: 22px;
            line-height: 1.3;
        }
    }
    .bottom-content {
        p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .job-items {
        margin-bottom: 40px;
    }
    .job-item  {
        background: $neutral-lighter;
        margin-bottom: 20px;
        padding: 30px;
        text-align: left;
        .top {
            position: relative;
            margin-bottom: 10px;
            border-bottom: 1px solid $neutral-light;
            .btn {
                position: absolute;
                min-width: initial;
                background: none;
                right: 0;
                top: 10px;
                margin: 0;
                &:hover {
                    background: $primary-color;
                }
                @include media-breakpoint-down(sm) {
                    position: relative;
                    top: 0;
                    margin-bottom: 20px;
                }
            }
        }
        h4 {
            text-transform: none;
            font-size: 14px;
            margin: 0;
            padding: 0; 
        }
        p {
            margin: 0;
        }
    }
    .btn { margin: 0; }
}



.job-detail {
    margin-bottom: 50px;
    .block {
        background: $neutral-lighter;
        margin-bottom: 20px;
        padding: 30px;
        position: relative;
        h3 {
            padding: 0;
        }
        .btn {
            position: absolute;
            min-width: initial;
            right: 30px;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 42px;
            @include media-breakpoint-down(sm) {
                position: relative;
                right: 0;
                margin-top: 10px;
            }
        }
    }
    h5 {
        padding-bottom: 10px;
        margin-top: 30px;
    }
    ul {
      list-style: none;
      margin-left: 1em;
    }
    ul li:before {
      content: "\2022";
      color: $primary-color;
      font-weight: bold;
      display: inline-block; 
      width: 1em;
      margin-left: -1em;
    }
    .side-content {
        margin-bottom: 20px;
        position: relative;
        padding-left: 35px;
        line-height: 1.4;
        &:first-child {
            margin-top: 30px;
        }
        span {
            font-weight: bold;
            display: block;
        }
        i {
            color: $primary-color;
            font-size: 20px;
            position: absolute;
            text-align: center;
            width: 25px;
            height: 22px;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}


.modal#apply {
    h3 {
        padding: 0;
        span {
            display: block;
            font-size: 12px;
            text-transform: uppercase;
        }
    }
    button.btn-primary {
        background: $button-background-color;
        border: 2px solid $button-background-color;
        font-weight: 700;
        line-height: 18px;
        margin: 9px 20px 3px 0;
        padding: 10px 25px;
        &:hover {
            background: none;
            color: $button-background-color;
        } 
    }
    .mura-response-success {
        color: $green-color;
        font-weight: 700;
    }
}