
// Service Bar
// -----------------------------------------------------------------------------

.service-bar{   
    padding: 18px 0 40px;
    @include media-breakpoint-up(md) {
        padding: 18px 0 60px;
    }
    @include media-breakpoint-up(lg) {
        padding: 18px 0 158px;
    }
    h3{
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        color: $black;
        padding: 0 0 15px;
        @include media-breakpoint-up(md) {
            font-size: 32px;
            line-height: 36px;
            padding: 0 0 10px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 36px;
            line-height: 40px;
            padding: 0 0 23px;
        }
    }
    p{
        font-size: 14px;
        line-height: 22px;
        color: $black;
        margin: 0 0 11px;
    }
    ul{
        padding: 0px;
        margin: 0px;
        list-style-type: none;        
        li{ 
            font-size: 13px;
            line-height: 18px;
            position: relative;
            padding: 0 0 9px 19px;                        
            &::before{
                content: "";
                @include position(absolute, 7px, auto, auto, 0px);
                width: 5px;
                height: 5px;
                background: $primary-color;
                @extend .rounded-circle;
            }      
        }
    } 
    .btn{
        margin: 10px 0 0;
        min-width: 161px;
        @include media-breakpoint-up(md) {
            margin: 16px 0 0;
        }
        @include media-breakpoint-up(lg) {
        }
    }
    .row{
        margin: 0px;
        padding: 30px 0 0;
        @extend .align-items-start;
        @include media-breakpoint-up(md) {
            padding: 40px 0 0;
        }
        @include media-breakpoint-up(lg) {
            padding: 65px 0 0;
        }
        &:nth-of-type(2n){            
            div[class|=col]{
                padding: 0px;
                &.image{ 
                    padding: 0px; 
                    @extend .order-2; 
                    @include media-breakpoint-down(sm) {
                        order: 0 !important;
                    } 
                }
                &.text{
                    padding: 25px 0px 10px;
                    @include media-breakpoint-up(md) {
                        padding: 4px 25px 0 0px;
                    }
                    @include media-breakpoint-up(lg) {
                        padding: 4px 60px 0 0px;
                    }
                }
            }
        }
        div[class|=col]{
            padding: 0;
            &.image{    
                padding: 0px; 
                @extend .order-0;   
                @include media-breakpoint-down(sm) {
                    order: 0 !important;
                }                                               
                img{
                    width: 100%;
                }
            }
            &.text{
                padding: 25px 0px 10px;
                margin: 0;
                @include media-breakpoint-up(md) {
                    padding: 0px 0px 0 25px;
                    margin: -3px 0 0;
                }
                @include media-breakpoint-up(lg) {
                    padding: 0px 0px 0 60px;
                }
            }
        }
    }
}